define("zerorisk-frontend/templates/components/generic/pg-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "UzQsJ4ez",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"card-header d-flex pt-2 pb-2 justify-content-between align-items-center \",[22,0,[\"headerClass\"]]]]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"card-title fs-13\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"symbol\"]]],null,{\"statements\":[[0,\"      \"],[7,\"i\"],[12,\"class\",[28,[\"fa fa-\",[21,\"symbol\"],\" m-r-5\"]]],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[1,[21,\"title\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"withControls\"]]],null,{\"statements\":[[0,\"      \"],[7,\"i\"],[12,\"class\",[28,[\"pg-arrow_\",[27,\"if\",[[23,[\"collapsed\"]],\"minimize\",\"maximize\"],null]]]],[9],[3,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"collapsed\"]]],null],[27,\"not\",[[23,[\"collapsed\"]]],null]]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"],[7,\"div\"],[12,\"class\",[28,[\"card-body \",[21,\"cardBodyClassNames\"]]]],[9],[0,\"\\n\"],[4,\"bs-collapse\",null,[[\"collapsed\"],[[23,[\"collapsed\"]]]],{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/generic/pg-card.hbs"
    }
  });
  _exports.default = _default;
});
define("zerorisk-frontend/templates/components/text-wizard/yes-no-answer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ve/MeIZx",
    "block": "{\"symbols\":[\"@answers\",\"@answer\"],\"statements\":[[7,\"div\"],[11,\"class\",\"d-flex\"],[11,\"style\",\"width: 50%; text-align: center; margin: 0 auto;\"],[9],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"btn btn-info bold btn-lg btn-xxl btn-rounded m-r-20\"],[11,\"style\",\"width: 100px;\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"app.button_yes\"],null],false],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],[22,2,[]],[22,1,[\"yesBringsTo\"]],null,\"YES\"]],[10],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"btn btn-info bold btn-lg btn-xxl btn-rounded m-r-20\"],[11,\"style\",\"width: 100px;\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"app.button_no\"],null],false],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],[22,2,[]],[22,1,[\"noBringsTo\"]],null,\"NO\"]],[10],[0,\"\\n\\n  \"],[7,\"button\"],[11,\"class\",\"btn btn-info bold btn-lg btn-xxl btn-rounded text-ellipsis\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"app.button_not_sure\"],null],false],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"openSupport\"]],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/text-wizard/yes-no-answer.hbs"
    }
  });
  _exports.default = _default;
});
define("zerorisk-frontend/templates/components/banners/covid-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "aSrQMkR2",
    "block": "{\"symbols\":[\"@bannerClass\",\"&default\"],\"statements\":[[4,\"if\",[[22,0,[\"isShowingBanner\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[28,[\"alert \",[22,1,[]],\" bordered d-flex \",[21,\"class\"]]]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"flex-grow-1 d-flex align-items-center\"],[9],[0,\"\\n      \"],[7,\"div\"],[9],[14,2],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/banners/covid-alert.hbs"
    }
  });
  _exports.default = _default;
});
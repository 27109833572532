define("zerorisk-frontend/helpers/truncate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.truncate = truncate;
  function truncate(params, hash) {
    var value = params[0];
    var len = hash.limit;
    var out = '';
    if (value !== undefined) {
      out = value.substr(0, len);
      if (value.length > len) {
        out += '...';
      }
    } else {
      out = '';
    }
    return out.replace(/<\/?[^>]+(>|$)/g, '');
  }
  var _default = Ember.Helper.helper(truncate);
  _exports.default = _default;
});
define("zerorisk-frontend/templates/components/location-entity-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "geoEKjhr",
    "block": "{\"symbols\":[\"@placeholder\",\"@searchQuery\",\"@isAllowSearchButton\"],\"statements\":[[7,\"div\"],[11,\"class\",\"location-entity-search d-flex align-items-center\"],[9],[0,\"\\n  \"],[7,\"i\"],[11,\"class\",\"fa fa-search\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n\"],[4,\"if\",[[22,3,[]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"input\",null,[[\"type\",\"class\",\"style\",\"placeholder\",\"value\",\"enter\"],[\"text\",\"form-new-control\",\"margin-top: 0 !important; cursor: pointer;\",[22,1,[]],[22,0,[\"value\"]],[27,\"action\",[[22,0,[]],\"onEnter\"],null]]]],false],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn btn-sm btn-complete btn-rounded\"],[12,\"disabled\",[22,0,[\"isDisabled\"]]],[9],[0,\"\\n\"],[4,\"bs-tooltip\",null,[[\"placement\",\"triggerEvents\"],[\"top\",\"hover\"]],{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"padding-5 d-flex align-items-center\"],[9],[0,\"\\n          \"],[1,[27,\"t\",[\"app.search\"],null],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[1,[27,\"t\",[\"app.search\"],null],false],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],\"requestSearch\"]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"debounced-input\",null,[[\"val\",\"wait\",\"class\",\"placeholder\",\"sync\"],[[22,2,[]],500,\"\",[22,1,[]],false]]],false],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/location-entity-search.hbs"
    }
  });
  _exports.default = _default;
});
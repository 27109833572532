define("zerorisk-frontend/templates/components/security/maturity/risk-stocks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "WfhoQHwU",
    "block": "{\"symbols\":[\"@model\",\"@title\",\"@openPreview\"],\"statements\":[[7,\"div\"],[11,\"class\",\"stock-price\"],[9],[0,\"\\n  \"],[7,\"span\"],[12,\"class\",[28,[\"score-label p-tag \",[27,\"risk-score-label\",[[22,1,[\"correlationScore\"]]],null]]]],[9],[0,\"\\n    \"],[1,[22,1,[\"correlationScoreAsLabel\"]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"stock-status\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"risk-header\"],[9],[1,[22,2,[]],false],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"risk-description\"],[9],[0,\"\\n    \"],[1,[22,1,[\"description\"]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"stock-alert\"],[9],[0,\"\\n  \"],[7,\"i\"],[11,\"class\",\"fa fa-2x fa-arrow-circle-o-right icon\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n\"],[3,\"action\",[[22,0,[]],[22,3,[]]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/security/maturity/risk-stocks.hbs"
    }
  });
  _exports.default = _default;
});
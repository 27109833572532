define("zerorisk-frontend/instance-initializers/hide-loading-screen", ["exports", "zerorisk-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  var userConfig = _environment.default['ember-load'] || {};
  function initialize() {
    var instance = arguments[1] || arguments[0];
    var container = !!arguments[1] ? arguments[0] : instance.container;
    if (Ember.View) {
      var ApplicationView = container.lookupFactory ? container.lookupFactory('view:application') : instance.resolveRegistration('view:application');
      ApplicationView = ApplicationView.reopen({
        didInsertElement: function didInsertElement() {
          this._super.apply(this, arguments);
          var loadingIndicatorClass = userConfig.loadingIndicatorClass || 'ember-load-indicator';
          Ember.$(".".concat(loadingIndicatorClass)).remove();
        }
      });
    }
  }
  var _default = {
    name: 'hide-loading-screen-instance',
    initialize: initialize
  };
  _exports.default = _default;
});
define("zerorisk-frontend/templates/components/modals/location-entity-selector-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "3++8pVS6",
    "block": "{\"symbols\":[\"@onLocationClick\",\"@mode\",\"@onClose\"],\"statements\":[[4,\"ember-remodal\",null,[[\"forService\",\"name\",\"onClose\"],[true,\"location-entity-selector-modal\",[22,3,[]]]],{\"statements\":[[0,\"  \"],[7,\"header\"],[11,\"class\",\"modal-header\"],[9],[0,\"\\n      \"],[7,\"h1\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[[27,\"concat\",[\"pinpoint.dynamic.choose_location.\",[22,0,[\"customization\",\"mpa\",\"labelMapping\",\"choose_location\"]]],null]],null],false],[0,\"\\n      \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"modal-body rounded-b-corners\"],[9],[0,\"\\n\\t\\t\"],[1,[27,\"location-entity-selector\",null,[[\"listIsVisible\",\"mode\",\"onLocationClick\",\"zoneLocked\",\"areaLocked\"],[true,[22,2,[]],[27,\"action\",[[22,0,[]],[22,1,[]]],null],[27,\"not\",[[27,\"or\",[[22,0,[\"currentUser\",\"isAdmin\"]],[22,0,[\"currentUser\",\"isZoneManager\"]]],null]],null],[27,\"not\",[[27,\"or\",[[22,0,[\"currentUser\",\"isAdmin\"]],[22,0,[\"currentUser\",\"isZoneManager\"]],[22,0,[\"currentUser\",\"isAreaManager\"]]],null]],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/modals/location-entity-selector-modal.hbs"
    }
  });
  _exports.default = _default;
});
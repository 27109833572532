define("zerorisk-frontend/utils/validators/intl/presence", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.presenceValidator = void 0;
  // TODO: Testing approach, then implement other validators in similar way (c) Sviat
  var presenceValidator = function presenceValidator(evtType, presence) {
    return (0, _emberCpValidations.validator)(evtType, {
      presence: presence,
      dependentKeys: ['model.intl.locale'],
      message: function message(type, options, value, context) {
        return this.model.intl.locale && this.createErrorMessage(type, value, context);
      }
    });
  };
  _exports.presenceValidator = presenceValidator;
});
define("zerorisk-frontend/utils/constants/file-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.STANDARD = _exports.SAQ = _exports.ROC = _exports.POLICY = _exports.OTHER = _exports.DIAGRAM = _exports.AOC = void 0;
  var POLICY = 'POLICY';
  _exports.POLICY = POLICY;
  var STANDARD = 'STANDARD';
  _exports.STANDARD = STANDARD;
  var ROC = 'ROC';
  _exports.ROC = ROC;
  var AOC = 'AOC';
  _exports.AOC = AOC;
  var OTHER = 'OTHER';
  _exports.OTHER = OTHER;
  var DIAGRAM = 'DIAGRAM';
  _exports.DIAGRAM = DIAGRAM;
  var SAQ = 'SAQ';
  _exports.SAQ = SAQ;
  var _default = [POLICY, STANDARD, ROC, AOC, OTHER, DIAGRAM];
  _exports.default = _default;
});
define("zerorisk-frontend/utils/types/invalidations", ["exports", "zerorisk-frontend/utils/types/-internal"], function (_exports, _internal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.InvalidationReasons = void 0;
  var InvalidationReasons = _internal.default.create({
    LOGOUT: 'logout',
    INACTIVITY: 'inactivity',
    NO_PRIVILEGE: 'no_privilege',
    LOGIN_AS: 'login_as',
    LOGIN_AS_ENDED: 'login_as_ended',
    DEFAULT: 'default'
  });
  _exports.InvalidationReasons = InvalidationReasons;
});
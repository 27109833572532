define("zerorisk-frontend/templates/components/audits/survey-question/checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "NdVOpbJC",
    "block": "{\"symbols\":[\"@surveyQuestion5Answer\",\"@willAnswerSurveyQuestion5\"],\"statements\":[[7,\"div\"],[11,\"class\",\"list-group-item border-left-0 border-right-0\"],[11,\"style\",\"border-bottom: 1px solid #ECECEC; z-index: 0;\"],[9],[0,\"\\n  \"],[1,[27,\"t\",[[23,[\"surveyQuestion\",\"text\"]]],null],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"list-group-item border-left-0 border-right-0 border-bottom-0 text-right\"],[11,\"style\",\"background-color: #FDFDFD;\"],[9],[0,\"\\n  \"],[7,\"img\"],[11,\"src\",\"/assets/img/speech-bubble-piece.png\"],[11,\"style\",\"height: 28px; margin-top: -21px; padding-right: 10px;\"],[9],[10],[0,\"\\n  \"],[7,\"span\"],[9],[0,\"\\n    \"],[1,[27,\"input\",null,[[\"type\",\"id\",\"checked\"],[\"checkbox\",\"want-to-report-checkbox\",[22,2,[]]]]],false],[0,\"\\n    \"],[7,\"label\"],[11,\"for\",\"want-to-report-checkbox\"],[9],[0,\"\\n      I want to report tampering evidences.\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"if\",[[22,2,[]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[9],[0,\"\\n      \"],[1,[27,\"textarea\",null,[[\"cols\",\"rows\",\"class\",\"placeholder\",\"value\"],[\"80\",\"8\",\"form-control\",\"Report your findings here.\",[22,1,[]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/audits/survey-question/checkbox.hbs"
    }
  });
  _exports.default = _default;
});
define("zerorisk-frontend/templates/components/saq-editor/sidebar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "r/z/I8Ki",
    "block": "{\"symbols\":[\"sectionQuestions\",\"section\",\"question\",\"@questions\"],\"statements\":[[7,\"div\"],[11,\"id\",\"sidebar-2\"],[11,\"class\",\"list-group m-b-0\"],[11,\"style\",\"padding: 10px 15px;\"],[9],[0,\"\\n\"],[4,\"each\",[[27,\"-each-in\",[[27,\"group-by\",[\"sectionNumber\",[22,4,[]]],null]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"bg-master-lightest m-t-10\"],[11,\"style\",\"padding: 16px; border-radius: 20px;\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"app.section\"],null],false],[0,\"\\n      \"],[1,[22,2,[]],false],[0,\"\\n    \"],[10],[0,\"\\n\"],[4,\"vertical-collection\",[[22,1,[]]],[[\"key\",\"estimateHeight\",\"staticHeight\",\"bufferSize\",\"renderAll\",\"containerSelector\"],[\"id\",45,true,0,false,\"#sidebar-1\"]],{\"statements\":[[0,\"      \"],[1,[27,\"saq-editor/sidebar-item\",null,[[\"question\",\"answer\",\"onSelectQuestion\"],[[22,3,[]],[22,3,[\"tempAnswer\"]],[22,0,[\"selectQuestion\"]]]]],false],[0,\"\\n\"]],\"parameters\":[3]},null]],\"parameters\":[1,2]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/saq-editor/sidebar.hbs"
    }
  });
  _exports.default = _default;
});
define("zerorisk-frontend/templates/components/modals/vulnerabilities-justification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Kbzq9olO",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ember-remodal\",null,[[\"forService\",\"name\"],[true,\"vulnerabilities-justification\"]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"modal-content\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"modal-header\"],[9],[0,\"\\n      \"],[7,\"h4\"],[11,\"class\",\"modal-title\"],[9],[1,[27,\"t\",[\"genesis.ips.fail_justification\"],null],false],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"modal-body\"],[9],[0,\"\\n      \"],[1,[27,\"textarea\",null,[[\"value\",\"class\",\"placeholder\"],[[22,0,[\"justification\"]],\"vuln-input form-control\",\"Describe why this vulnerability doesn't impact your PCI DSS compliance and how..\"]]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"modal-footer\"],[9],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"btn btn-default\"],[11,\"data-dismiss\",\"modal\"],[11,\"type\",\"button\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"app.button_close\"],null],false],[0,\"\\n      \"],[3,\"action\",[[22,0,[]],\"closeVulnerabilitiesModal\"]],[10],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"btn btn-success\"],[11,\"type\",\"button\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"app.submit\"],null],false],[0,\"\\n      \"],[3,\"action\",[[22,0,[]],\"saveJustification\"]],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/modals/vulnerabilities-justification.hbs"
    }
  });
  _exports.default = _default;
});
define("zerorisk-frontend/templates/components/pg-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5/a7X31I",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[27,\"or\",[[22,0,[\"navi\",\"isInIndexRoute\"]],[22,0,[\"navi\",\"isInSaqEditRoute\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"footer container-fluid container-fixed-lg m-t-20\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"copyright sm-text-center\"],[9],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"small no-margin sm-pull-reset\"],[9],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"hint-text\"],[9],[1,[27,\"t\",[\"app.copyright\"],null],false],[10],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"font-montserrat\"],[9],[0,\"ZeroRisk\"],[10],[0,\".\\n        \"],[7,\"span\"],[11,\"class\",\"hint-text\"],[9],[1,[27,\"t\",[\"app.all_rights_reserved\"],null],false],[10],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"sm-block hint-text\"],[9],[0,\"\\n          \"],[7,\"a\"],[12,\"href\",[28,[[27,\"href-to\",[\"legal.terms-of-use\"],null]]]],[11,\"class\",\"m-l-10 m-r-10\"],[9],[0,\"\\n            \"],[1,[27,\"t\",[\"app.terms_of_use\"],null],false],[0,\"\\n          \"],[10],[0,\" |\\n          \"],[7,\"a\"],[12,\"href\",[28,[[27,\"href-to\",[\"legal.privacy-policy\"],null]]]],[11,\"class\",\"m-l-10\"],[9],[0,\"\\n            \"],[1,[27,\"t\",[\"app.privacy_policy\"],null],false],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"clearfix\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/pg-footer.hbs"
    }
  });
  _exports.default = _default;
});
define("zerorisk-frontend/cldrs/ro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*jslint eqeq: true*/
  var _default = [{
    "locale": "ro",
    "pluralRuleFunction": function pluralRuleFunction(n, ord) {
      var s = String(n).split("."),
        v0 = !s[1],
        t0 = Number(s[0]) == n,
        n100 = t0 && s[0].slice(-2);
      if (ord) return n == 1 ? "one" : "other";
      return n == 1 && v0 ? "one" : !v0 || n == 0 || n != 1 && n100 >= 1 && n100 <= 19 ? "few" : "other";
    },
    "fields": {
      "year": {
        "displayName": "an",
        "relative": {
          "0": "anul acesta",
          "1": "anul viitor",
          "-1": "anul trecut"
        },
        "relativeTime": {
          "future": {
            "one": "peste {0} an",
            "few": "peste {0} ani",
            "other": "peste {0} de ani"
          },
          "past": {
            "one": "acum {0} an",
            "few": "acum {0} ani",
            "other": "acum {0} de ani"
          }
        }
      },
      "year-short": {
        "displayName": "an",
        "relative": {
          "0": "anul acesta",
          "1": "anul viitor",
          "-1": "anul trecut"
        },
        "relativeTime": {
          "future": {
            "one": "peste {0} an",
            "few": "peste {0} ani",
            "other": "peste {0} de ani"
          },
          "past": {
            "one": "acum {0} an",
            "few": "acum {0} ani",
            "other": "acum {0} de ani"
          }
        }
      },
      "month": {
        "displayName": "lună",
        "relative": {
          "0": "luna aceasta",
          "1": "luna viitoare",
          "-1": "luna trecută"
        },
        "relativeTime": {
          "future": {
            "one": "peste {0} lună",
            "few": "peste {0} luni",
            "other": "peste {0} de luni"
          },
          "past": {
            "one": "acum {0} lună",
            "few": "acum {0} luni",
            "other": "acum {0} de luni"
          }
        }
      },
      "month-short": {
        "displayName": "lună",
        "relative": {
          "0": "luna aceasta",
          "1": "luna viitoare",
          "-1": "luna trecută"
        },
        "relativeTime": {
          "future": {
            "one": "peste {0} lună",
            "few": "peste {0} luni",
            "other": "peste {0} luni"
          },
          "past": {
            "one": "acum {0} lună",
            "few": "acum {0} luni",
            "other": "acum {0} luni"
          }
        }
      },
      "day": {
        "displayName": "zi",
        "relative": {
          "0": "azi",
          "1": "mâine",
          "2": "poimâine",
          "-2": "alaltăieri",
          "-1": "ieri"
        },
        "relativeTime": {
          "future": {
            "one": "peste {0} zi",
            "few": "peste {0} zile",
            "other": "peste {0} de zile"
          },
          "past": {
            "one": "acum {0} zi",
            "few": "acum {0} zile",
            "other": "acum {0} de zile"
          }
        }
      },
      "day-short": {
        "displayName": "zi",
        "relative": {
          "0": "azi",
          "1": "mâine",
          "2": "poimâine",
          "-2": "alaltăieri",
          "-1": "ieri"
        },
        "relativeTime": {
          "future": {
            "one": "peste {0} zi",
            "few": "peste {0} zile",
            "other": "peste {0} de zile"
          },
          "past": {
            "one": "acum {0} zi",
            "few": "acum {0} zile",
            "other": "acum {0} de zile"
          }
        }
      },
      "hour": {
        "displayName": "oră",
        "relative": {
          "0": "ora aceasta"
        },
        "relativeTime": {
          "future": {
            "one": "peste {0} oră",
            "few": "peste {0} ore",
            "other": "peste {0} de ore"
          },
          "past": {
            "one": "acum {0} oră",
            "few": "acum {0} ore",
            "other": "acum {0} de ore"
          }
        }
      },
      "hour-short": {
        "displayName": "h",
        "relative": {
          "0": "ora aceasta"
        },
        "relativeTime": {
          "future": {
            "one": "peste {0} h",
            "few": "peste {0} h",
            "other": "peste {0} h"
          },
          "past": {
            "one": "acum {0} h",
            "few": "acum {0} h",
            "other": "acum {0} h"
          }
        }
      },
      "minute": {
        "displayName": "minut",
        "relative": {
          "0": "minutul acesta"
        },
        "relativeTime": {
          "future": {
            "one": "peste {0} minut",
            "few": "peste {0} minute",
            "other": "peste {0} de minute"
          },
          "past": {
            "one": "acum {0} minut",
            "few": "acum {0} minute",
            "other": "acum {0} de minute"
          }
        }
      },
      "minute-short": {
        "displayName": "min.",
        "relative": {
          "0": "minutul acesta"
        },
        "relativeTime": {
          "future": {
            "one": "peste {0} min.",
            "few": "peste {0} min.",
            "other": "peste {0} min."
          },
          "past": {
            "one": "acum {0} min.",
            "few": "acum {0} min.",
            "other": "acum {0} min."
          }
        }
      },
      "second": {
        "displayName": "secundă",
        "relative": {
          "0": "acum"
        },
        "relativeTime": {
          "future": {
            "one": "peste {0} secundă",
            "few": "peste {0} secunde",
            "other": "peste {0} de secunde"
          },
          "past": {
            "one": "acum {0} secundă",
            "few": "acum {0} secunde",
            "other": "acum {0} de secunde"
          }
        }
      },
      "second-short": {
        "displayName": "sec.",
        "relative": {
          "0": "acum"
        },
        "relativeTime": {
          "future": {
            "one": "peste {0} sec.",
            "few": "peste {0} sec.",
            "other": "peste {0} sec."
          },
          "past": {
            "one": "acum {0} sec.",
            "few": "acum {0} sec.",
            "other": "acum {0} sec."
          }
        }
      }
    }
  }];
  _exports.default = _default;
});
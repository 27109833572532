define("zerorisk-frontend/utils/constants/saq-types", ["exports", "@babel/runtime/helpers/esm/defineProperty"], function (_exports, _defineProperty2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SAQTypes = void 0;
  var _SAQTypes$SAQ_A$SAQTy;
  var SAQTypes = {
    SAQ_A: 'SAQ_A',
    SAQ_A_EP: 'SAQ_A_EP',
    SAQ_A_REDIRECT: 'SAQ_A_REDIRECT',
    SAQ_A_IFRAME: 'SAQ_A_IFRAME',
    SAQ_B: 'SAQ_B',
    SAQ_B_IP: 'SAQ_B_IP',
    SAQ_C: 'SAQ_C',
    SAQ_C_VT: 'SAQ_C_VT',
    SAQ_D: 'SAQ_D',
    SAQ_D_SP: 'SAQ_D_SP',
    SAQ_P2PE: 'SAQ_P2PE',
    SAQ_SPOC: 'SAQ_SPOC',
    ROC: 'ROC',
    UNDERTERMINED: 'UNDERTERMINED',
    UNSET: 'UNSET',
    OTHER: 'OTHER',
    UPLOADED: 'UPLOADED'
  };
  _exports.SAQTypes = SAQTypes;
  var _default = (_SAQTypes$SAQ_A$SAQTy = {}, (0, _defineProperty2.default)(_SAQTypes$SAQ_A$SAQTy, SAQTypes.SAQ_A, {
    friendly: 'SAQ A',
    short: 'A',
    businessChannel: 'E_COMMERCE'
  }), (0, _defineProperty2.default)(_SAQTypes$SAQ_A$SAQTy, SAQTypes.SAQ_A_EP, {
    friendly: 'SAQ A-EP',
    short: 'A-EP',
    businessChannel: 'OTHER'
  }), (0, _defineProperty2.default)(_SAQTypes$SAQ_A$SAQTy, SAQTypes.SAQ_A_REDIRECT, {
    friendly: 'SAQ A REDIRECT',
    short: 'A-REDIRECT',
    businessChannel: 'OTHER'
  }), (0, _defineProperty2.default)(_SAQTypes$SAQ_A$SAQTy, SAQTypes.SAQ_A_IFRAME, {
    friendly: 'SAQ A IFRAME',
    short: 'A-IFRAME',
    businessChannel: 'OTHER'
  }), (0, _defineProperty2.default)(_SAQTypes$SAQ_A$SAQTy, SAQTypes.SAQ_B, {
    friendly: 'SAQ B',
    short: 'B',
    businessChannel: 'F2F'
  }), (0, _defineProperty2.default)(_SAQTypes$SAQ_A$SAQTy, SAQTypes.SAQ_B_IP, {
    friendly: 'SAQ B-IP',
    short: 'B-IP',
    businessChannel: 'F2F'
  }), (0, _defineProperty2.default)(_SAQTypes$SAQ_A$SAQTy, SAQTypes.SAQ_C, {
    friendly: 'SAQ C',
    short: 'C',
    businessChannel: 'F2F'
  }), (0, _defineProperty2.default)(_SAQTypes$SAQ_A$SAQTy, SAQTypes.SAQ_C_VT, {
    friendly: 'SAQ C-VT',
    short: 'C-VT',
    businessChannel: 'F2F'
  }), (0, _defineProperty2.default)(_SAQTypes$SAQ_A$SAQTy, SAQTypes.SAQ_D, {
    friendly: 'SAQ D',
    short: 'D',
    businessChannel: 'F2F'
  }), (0, _defineProperty2.default)(_SAQTypes$SAQ_A$SAQTy, SAQTypes.SAQ_D_SP, {
    friendly: 'SAQ D Service Providers',
    short: 'D-SP',
    businessChannel: 'F2F'
  }), (0, _defineProperty2.default)(_SAQTypes$SAQ_A$SAQTy, SAQTypes.SAQ_P2PE, {
    friendly: 'SAQ P2PE',
    short: 'P2PE',
    businessChannel: 'F2F'
  }), (0, _defineProperty2.default)(_SAQTypes$SAQ_A$SAQTy, SAQTypes.SPOC, {
    friendly: 'SAQ SPoC',
    short: 'SPoC',
    businessChannel: 'F2F'
  }), (0, _defineProperty2.default)(_SAQTypes$SAQ_A$SAQTy, SAQTypes.ROC, {
    friendly: 'ROC',
    short: 'ROC',
    chartIgnore: true,
    businessChannel: 'OTHER'
  }), (0, _defineProperty2.default)(_SAQTypes$SAQ_A$SAQTy, SAQTypes.UNDERTERMINED, {
    friendly: 'UNDERTERMINED',
    short: 'UNDERTERMINED',
    chartIgnore: true,
    businessChannel: 'OTHER'
  }), (0, _defineProperty2.default)(_SAQTypes$SAQ_A$SAQTy, SAQTypes.UNSET, {
    friendly: 'UNSET',
    short: 'UNSET',
    chartIgnore: true,
    businessChannel: 'OTHER'
  }), (0, _defineProperty2.default)(_SAQTypes$SAQ_A$SAQTy, SAQTypes.OTHER, {
    friendly: 'Other',
    short: 'Other',
    chartIgnore: true
  }), (0, _defineProperty2.default)(_SAQTypes$SAQ_A$SAQTy, SAQTypes.UPLOADED, {
    friendly: 'TBD',
    short: 'TBD',
    chartIgnore: true
  }), _SAQTypes$SAQ_A$SAQTy);
  _exports.default = _default;
});
define("zerorisk-frontend/templates/components/modals/language-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "AVI208K4",
    "block": "{\"symbols\":[\"modal\",\"locale\"],\"statements\":[[4,\"ember-remodal\",null,[[\"forService\",\"name\"],[true,\"language-selector\"]],{\"statements\":[[0,\"  \"],[7,\"header\"],[11,\"class\",\"modal-header\"],[11,\"style\",\"padding:20px 30px;\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"font-montserrat text-uppercase text-master text-white\"],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"fa fa-globe m-r-5\"],[9],[10],[0,\" \"],[1,[27,\"t\",[\"app.language\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"modal-body padding-0\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"list-group fs-15 text-center\"],[9],[0,\"\\n\"],[4,\"each\",[[22,0,[\"locales\"]]],null,{\"statements\":[[0,\"      \"],[7,\"a\"],[12,\"class\",[28,[\"list-group-item \",[27,\"if\",[[22,2,[\"active\"]],\"active\"],null]]]],[9],[0,\"\\n        \"],[1,[22,2,[\"text\"]],false],[0,\"\\n      \"],[3,\"action\",[[22,0,[]],\"setLocale\",[22,2,[\"id\"]]]],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"modal-footer\"],[11,\"style\",\"padding:20px 30px;\"],[9],[0,\"\\n\"],[4,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"cancel\"]],\"expected `modal.cancel` to be a contextual component but found a string. Did you mean `(component modal.cancel)`? ('zerorisk-frontend/templates/components/modals/language-selector.hbs' @ L19:C7) \"],null]],null,{\"statements\":[[0,\"      \"],[7,\"button\"],[11,\"class\",\"btn transparent no-depth no-border\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"app.closewindow\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/modals/language-selector.hbs"
    }
  });
  _exports.default = _default;
});
define("zerorisk-frontend/templates/components/modals/update-flag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "YM6juL6g",
    "block": "{\"symbols\":[\"@selectedFlag\"],\"statements\":[[4,\"ember-remodal\",null,[[\"forService\",\"name\"],[true,\"update-flag\"]],{\"statements\":[[0,\"  \"],[7,\"header\"],[11,\"class\",\"modal-header\"],[9],[0,\"\\n    \"],[7,\"h1\"],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"fa fa-flag m-r-5\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\" \"],[1,[27,\"t\",[\"mpa.merchant.flags.update_alarm\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"modal-body rounded-b-corners p-t-40\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"p-b-25\"],[9],[0,\"\\n      \"],[7,\"span\"],[12,\"class\",[28,[\"fs-14 label \",[22,1,[\"cssLabelClass\"]]]]],[9],[0,\"\\n        \"],[1,[22,1,[\"status\"]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[1,[27,\"forms/merchant/management/update-flag\",null,[[\"flag\",\"onComplete\"],[[22,1,[]],[27,\"action\",[[22,0,[]],\"onUpdateComplete\"],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/modals/update-flag.hbs"
    }
  });
  _exports.default = _default;
});
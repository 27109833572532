define("zerorisk-frontend/templates/components/location-entity-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "lp4S9wuh",
    "block": "{\"symbols\":[\"@onClick\",\"&default\"],\"statements\":[[0,\"\\t\"],[7,\"div\"],[11,\"class\",\"card card-default box-shadow-spread location-card m-t-5 m-b-5\"],[11,\"style\",\"border-radius: 5px;\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row no-margin\"],[9],[0,\"\\n      \"],[7,\"div\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],[22,1,[]]],null]],[11,\"class\",\"col-1 d-flex flex-wrap align-items-center text-master my-auto\"],[11,\"style\",\"padding: 15px 20px;\"],[9],[0,\"\\n        \"],[14,2,[[27,\"hash\",null,[[\"icon\"],[true]]]]],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],[22,1,[]]],null]],[11,\"class\",\"col-10 d-flex flex-wrap align-items-center\"],[11,\"style\",\"padding: 15px;\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[14,2,[[27,\"hash\",null,[[\"body\"],[true]]]]],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col-1 d-flex flex-wrap align-items-center text-center my-auto\"],[11,\"style\",\"padding: 15px;\"],[9],[0,\"\\n      \\t\"],[14,2,[[27,\"hash\",null,[[\"actions\"],[true]]]]],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/location-entity-item.hbs"
    }
  });
  _exports.default = _default;
});
define("zerorisk-frontend/cldrs/bg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*jslint eqeq: true*/
  var _default = [{
    "locale": "bg",
    "pluralRuleFunction": function pluralRuleFunction(n, ord) {
      if (ord) return "other";
      return n == 1 ? "one" : "other";
    },
    "fields": {
      "year": {
        "displayName": "година",
        "relative": {
          "0": "тази година",
          "1": "следващата година",
          "-1": "миналата година"
        },
        "relativeTime": {
          "future": {
            "one": "след {0} година",
            "other": "след {0} години"
          },
          "past": {
            "one": "преди {0} година",
            "other": "преди {0} години"
          }
        }
      },
      "year-short": {
        "displayName": "г.",
        "relative": {
          "0": "т. г.",
          "1": "следв. г.",
          "-1": "мин. г."
        },
        "relativeTime": {
          "future": {
            "one": "след {0} г.",
            "other": "след {0} г."
          },
          "past": {
            "one": "преди {0} г.",
            "other": "преди {0} г."
          }
        }
      },
      "month": {
        "displayName": "месец",
        "relative": {
          "0": "този месец",
          "1": "следващ месец",
          "-1": "предходен месец"
        },
        "relativeTime": {
          "future": {
            "one": "след {0} месец",
            "other": "след {0} месеца"
          },
          "past": {
            "one": "преди {0} месец",
            "other": "преди {0} месеца"
          }
        }
      },
      "month-short": {
        "displayName": "мес.",
        "relative": {
          "0": "този мес.",
          "1": "следв. мес.",
          "-1": "мин. мес."
        },
        "relativeTime": {
          "future": {
            "one": "след {0} м.",
            "other": "след {0} м."
          },
          "past": {
            "one": "преди {0} м.",
            "other": "преди {0} м."
          }
        }
      },
      "day": {
        "displayName": "ден",
        "relative": {
          "0": "днес",
          "1": "утре",
          "2": "вдругиден",
          "-2": "онзи ден",
          "-1": "вчера"
        },
        "relativeTime": {
          "future": {
            "one": "след {0} ден",
            "other": "след {0} дни"
          },
          "past": {
            "one": "преди {0} ден",
            "other": "преди {0} дни"
          }
        }
      },
      "day-short": {
        "displayName": "д",
        "relative": {
          "0": "днес",
          "1": "утре",
          "2": "вдругиден",
          "-2": "онзи ден",
          "-1": "вчера"
        },
        "relativeTime": {
          "future": {
            "one": "след {0} ден",
            "other": "след {0} дни"
          },
          "past": {
            "one": "преди {0} ден",
            "other": "преди {0} дни"
          }
        }
      },
      "hour": {
        "displayName": "час",
        "relative": {
          "0": "в този час"
        },
        "relativeTime": {
          "future": {
            "one": "след {0} час",
            "other": "след {0} часа"
          },
          "past": {
            "one": "преди {0} час",
            "other": "преди {0} часа"
          }
        }
      },
      "hour-short": {
        "displayName": "ч",
        "relative": {
          "0": "в този час"
        },
        "relativeTime": {
          "future": {
            "one": "след {0} ч",
            "other": "след {0} ч"
          },
          "past": {
            "one": "преди {0} ч",
            "other": "преди {0} ч"
          }
        }
      },
      "minute": {
        "displayName": "минута",
        "relative": {
          "0": "в тази минута"
        },
        "relativeTime": {
          "future": {
            "one": "след {0} минута",
            "other": "след {0} минути"
          },
          "past": {
            "one": "преди {0} минута",
            "other": "преди {0} минути"
          }
        }
      },
      "minute-short": {
        "displayName": "мин",
        "relative": {
          "0": "в тази минута"
        },
        "relativeTime": {
          "future": {
            "one": "след {0} мин",
            "other": "след {0} мин"
          },
          "past": {
            "one": "преди {0} мин",
            "other": "преди {0} мин"
          }
        }
      },
      "second": {
        "displayName": "секунда",
        "relative": {
          "0": "сега"
        },
        "relativeTime": {
          "future": {
            "one": "след {0} секунда",
            "other": "след {0} секунди"
          },
          "past": {
            "one": "преди {0} секунда",
            "other": "преди {0} секунди"
          }
        }
      },
      "second-short": {
        "displayName": "сек",
        "relative": {
          "0": "сега"
        },
        "relativeTime": {
          "future": {
            "one": "след {0} сек",
            "other": "след {0} сек"
          },
          "past": {
            "one": "преди {0} сек",
            "other": "преди {0} сек"
          }
        }
      }
    }
  }];
  _exports.default = _default;
});
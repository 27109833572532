define("zerorisk-frontend/templates/auth/saml-login-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Ps8a7rrK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n\\t\"],[7,\"div\"],[11,\"class\",\"bg-white full-height padding-40\"],[9],[0,\"\\n\\t\\t\"],[1,[27,\"error-box\",null,[[\"errors\"],[[22,0,[\"error\"]]]]],false],[0,\"\\n\\t\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/auth/saml-login-url.hbs"
    }
  });
  _exports.default = _default;
});
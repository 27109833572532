define("zerorisk-frontend/templates/components/saq-editor/evidences", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "wV/xekQb",
    "block": "{\"symbols\":[\"@answer\",\"@question\",\"@saq\"],\"statements\":[[7,\"i\"],[11,\"class\",\"fa fa-circle text-white m-t-15 m-b-15\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"id\",\"saq-file-upload\"],[11,\"class\",\"m-b-15 evidences-upload-attach-container\"],[9],[0,\"\\n\"],[4,\"bs-tooltip\",null,[[\"placement\",\"triggerEvents\"],[\"top\",\"hover\"]],{\"statements\":[[0,\"    \"],[1,[27,\"t\",[\"saq.editor.button_upload_evidence.subtitle\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"btn btn-rounded btn-lg btn-larger btn-info text-center w-100 file-upload-modal-open-button d-flex align-items-center justify-content-around\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"saq.editor.button_upload_evidence.title\"],null],false],[0,\"\\n\"],[4,\"if\",[[22,1,[\"evidencesCount\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\"],[11,\"class\",\"label label-success pull-right\"],[9],[0,\"\\n        \"],[1,[22,1,[\"evidencesCount\"]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[3,\"action\",[[22,0,[]],\"openEvidencesUploaderModal\"]],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[27,\"modals/evidences-uploader\",null,[[\"modelType\",\"saq\",\"question\",\"answer\"],[\"answer\",[22,3,[]],[22,2,[]],[22,1,[]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/saq-editor/evidences.hbs"
    }
  });
  _exports.default = _default;
});
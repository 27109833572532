define("zerorisk-frontend/utils/helpers/IE", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.detectIE = detectIE;
  function substring(userAgent, index, length) {
    return userAgent.substring(index + length, userAgent.indexOf('.', index));
  }
  function detectIE() {
    var userAgent = window.navigator.userAgent;
    var msie = userAgent.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(substring(userAgent, msie, 5), 10);
    }
    var trident = userAgent.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      var rv = userAgent.indexOf('rv:');
      return parseInt(substring(userAgent, rv, 3), 10);
    }
    var edge = userAgent.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(substring(userAgent, edge, 5), 10);
    }

    // other browser
    return false;
  }
});
define("zerorisk-frontend/templates/devices/device/audits/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "8zExdNHV",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"loading-spinner\",null,[[\"class\"],[\"m-t-60\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/devices/device/audits/loading.hbs"
    }
  });
  _exports.default = _default;
});
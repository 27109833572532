define("zerorisk-frontend/utils/enums/index", ["exports", "zerorisk-frontend/utils/enums/payment-gateway", "zerorisk-frontend/utils/enums/message-type", "zerorisk-frontend/utils/enums/flag-action", "zerorisk-frontend/utils/enums/entity-type"], function (_exports, _paymentGateway, _messageType, _flagAction, _entityType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.keys(_paymentGateway).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _paymentGateway[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function get() {
        return _paymentGateway[key];
      }
    });
  });
  Object.keys(_messageType).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _messageType[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function get() {
        return _messageType[key];
      }
    });
  });
  Object.keys(_flagAction).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _flagAction[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function get() {
        return _flagAction[key];
      }
    });
  });
  Object.keys(_entityType).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _entityType[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function get() {
        return _entityType[key];
      }
    });
  });
});
define("zerorisk-frontend/templates/ips/vulnerabilities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "LpFQfuRd",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n  \"],[1,[27,\"pg-navbar\",null,[[\"symbol\"],[\"cubes\"]]],false],[0,\"\\n\\n  \"],[1,[27,\"containers/ips/vulnerabilities-list\",null,[[\"model\"],[[22,0,[\"model\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/ips/vulnerabilities.hbs"
    }
  });
  _exports.default = _default;
});
define("zerorisk-frontend/helpers/vanilla-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.vanillaObject = vanillaObject;
  function vanillaObject(params, hash) {
    var object = {};
    Ember.assign(object, hash);
    return object;
  }
  var _default = Ember.Helper.helper(vanillaObject);
  _exports.default = _default;
});
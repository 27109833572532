define("zerorisk-frontend/templates/components/mpa/hierarchy-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "L13N+1qk",
    "block": "{\"symbols\":[\"pool\",\"index\",\"@redirectToMerchants\"],\"statements\":[[7,\"div\"],[11,\"class\",\"pool-container m-t-15 p-b-15\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row d-flex justify-content-center\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"d-flex justify-content-between align-items-center\"],[11,\"style\",\"min-width:250px;\"],[9],[0,\"\\n      \"],[1,[27,\"mpa/hierarchy-card\",null,[[\"mpa\",\"showSelect\",\"redirectToMerchants\"],[[23,[\"model\"]],false,[22,3,[]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"each\",[[22,0,[\"pools\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"mpa/hierarchy-pool\",null,[[\"pool\",\"parent\",\"canGoBack\",\"activateNextPool\",\"deactivateLastPool\",\"redirectToMerchants\"],[[22,1,[]],[22,1,[\"parent\"]],[27,\"and\",[[27,\"gt\",[[22,0,[\"pools\",\"length\"]],1],null],[27,\"eq\",[[27,\"dec\",[[22,0,[\"pools\",\"length\"]]],null],[22,2,[]]],null]],null],[27,\"action\",[[22,0,[]],\"activateNextPool\"],null],[27,\"action\",[[22,0,[]],\"deactivateLastPool\"],null],[22,3,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/mpa/hierarchy-view.hbs"
    }
  });
  _exports.default = _default;
});
define("zerorisk-frontend/templates/user/verify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "w4xE/cBa",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"container\"],[11,\"style\",\"max-width: 500px; margin-top: 30px;\"],[9],[0,\"\\n\\t\"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\"],[11,\"class\",\"card-panel\"],[9],[0,\"\\n\"],[4,\"if\",[[22,0,[\"verified\"]]],null,{\"statements\":[[0,\"\\t  \\t\\t\\t\"],[7,\"h5\"],[11,\"class\",\"\"],[11,\"style\",\"width: 100%; margin-top: 0px;\"],[9],[0,\" Account verified.\"],[10],[0,\"\\n\\t  \\t\\t\\t You will be redirected to the login page in a few moments.\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[22,0,[\"error\"]]],null,{\"statements\":[[0,\"\\t  \\t\\t\\t\"],[7,\"h5\"],[11,\"class\",\"\"],[11,\"style\",\"width: 100%; margin-top: 0px;\"],[9],[0,\" \"],[1,[22,0,[\"error\"]],false],[10],[0,\"\\n\\t  \\t\\t\\t If you're having troubles activating your account, you can try requesting another activation email here or you can contact support.\\n\"]],\"parameters\":[]},null],[0,\"  \\t\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/user/verify.hbs"
    }
  });
  _exports.default = _default;
});
define("zerorisk-frontend/utils/constants/file-uploader-constants", ["exports", "zerorisk-frontend/utils/paths"], function (_exports, _paths) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mimeTypes = _exports.fileTypeIcons = _exports.extensions = _exports.FILE_UPLOADER_ENDPOINT = _exports.FILE_MAX_SIZE = void 0;
  var FILE_MAX_SIZE = 5000000 * 20; //bytes
  _exports.FILE_MAX_SIZE = FILE_MAX_SIZE;
  var FILE_UPLOADER_ENDPOINT = "".concat(_paths.host, "/api/v2/shared/files");
  _exports.FILE_UPLOADER_ENDPOINT = FILE_UPLOADER_ENDPOINT;
  var mimeTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/jpeg', 'image/pjpeg', 'image/png', 'image/gif', 'text/csv', 'text/plain', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'];
  _exports.mimeTypes = mimeTypes;
  var extensions = ['pdf', 'doc', 'docx', 'jpg', 'jpeg', 'png', 'gif', 'txt', 'csv', 'xls', 'xlsx', 'ppt', 'pptx'];
  _exports.extensions = extensions;
  var fileTypeIcons = {
    pdf: 'file-pdf',
    doc: 'file-word',
    docx: 'file-word',
    jpg: 'file-image',
    jpeg: 'file-image',
    png: 'file-image',
    gif: 'file-image',
    txt: 'file-text',
    csv: 'file-excel',
    xls: 'file-excel',
    xlsx: 'file-excel',
    ppt: 'file-powerpoint',
    pptx: 'file-powerpoint'
  };
  _exports.fileTypeIcons = fileTypeIcons;
});
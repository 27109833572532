define("zerorisk-frontend/helpers/split-path", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.splitPath = splitPath;
  function splitPath(params, hash) {
    var value = params[0];
    return value.split('/')[hash.elementPosition];
  }
  var _default = Ember.Helper.helper(splitPath);
  _exports.default = _default;
});
define("zerorisk-frontend/utils/paths", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.subDomain = _exports.rootURL = _exports.namespace = _exports.hostName = _exports.host = _exports.flavor = _exports.cookieDomain = void 0;
  var _window$location = window.location,
    oroginalHost = _window$location.host,
    protocol = _window$location.protocol,
    hostname = _window$location.hostname;
  var _oroginalHost$split = oroginalHost.split('.'),
    _oroginalHost$split2 = (0, _slicedToArray2.default)(_oroginalHost$split, 1),
    sub = _oroginalHost$split2[0];
  var rootURL = '/';
  _exports.rootURL = rootURL;
  var namespace = 'api/v2';
  _exports.namespace = namespace;
  var host = "".concat(protocol, "//").concat(oroginalHost);
  _exports.host = host;
  var hostName = hostname;
  _exports.hostName = hostName;
  var subDomain = sub === 'zerorisk' ? null : sub;
  _exports.subDomain = subDomain;
  var flavor = subDomain;
  _exports.flavor = flavor;
  var cookieDomain = hostname;
  _exports.cookieDomain = cookieDomain;
});
define("zerorisk-frontend/templates/enrollment/user-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "pjvGEG9M",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row column-seperation padding-15\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-md-12\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row row-same-height\"],[11,\"style\",\"backface-visibility: hidden;\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col-md-5 b-r b-dashed b-grey\"],[11,\"style\",\"backface-visibility: hidden;\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"padding-30\"],[9],[0,\"\\n          \"],[7,\"h3\"],[11,\"class\",\"font-montserrat m-t-0\"],[9],[0,\"\\n            \"],[1,[27,\"t\",[\"enrollment.user_details.user_details_part_1\"],null],false],[0,\"\\n          \"],[10],[0,\"\\n          \"],[7,\"p\"],[9],[0,\"\\n            \"],[1,[27,\"t\",[\"enrollment.user_details.user_details_part_2\"],null],false],[0,\"\\n          \"],[10],[0,\"\\n          \"],[7,\"p\"],[11,\"class\",\"m-t-20\"],[9],[0,\"\\n            \"],[7,\"h5\"],[11,\"class\",\"m-b-10 font-montserrat\"],[9],[0,\"\\n              \"],[1,[27,\"t\",[\"enrollment.user_details.user_details_part_3\"],null],false],[0,\"\\n            \"],[10],[0,\"\\n            \"],[1,[27,\"t\",[\"enrollment.user_details.user_details_part_4\"],null],false],[0,\"\\n          \"],[10],[0,\"\\n          \"],[7,\"p\"],[11,\"class\",\"m-t-20 small hint-text\"],[9],[0,\"\\n            \"],[1,[27,\"t\",[\"enrollment.user_details.user_details_part_5\"],null],false],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col-md-7\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"padding-30\"],[9],[0,\"\\n          \"],[1,[27,\"user-editor\",null,[[\"model\",\"saveText\",\"onComplete\"],[[22,0,[\"model\"]],[27,\"t\",[\"app.button_next\"],null],[27,\"action\",[[22,0,[]],\"goToNextStep\"],null]]]],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/enrollment/user-details.hbs"
    }
  });
  _exports.default = _default;
});
define("zerorisk-frontend/templates/invitation/sp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "uXcn+Lgs",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[22,0,[\"isLoading\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\"],[11,\"class\",\"m-t-50\"],[9],[0,\"\\n\\t\\t\"],[1,[21,\"loading-spinner\"],false],[0,\"\\n\\t\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[15,[22,0,[\"activeStepTemplate\"]],[]],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/invitation/sp.hbs"
    }
  });
  _exports.default = _default;
});
define("zerorisk-frontend/helpers/badge-score-label", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "@babel/runtime/helpers/esm/defineProperty"], function (_exports, _slicedToArray2, _defineProperty2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.badgeScoreLabel = badgeScoreLabel;
  _exports.default = void 0;
  var _DEFAULT_RISK_GRADE_M, _LABEL_CLASS_MAP;
  var RISK_LEVELS_MAP = {
    'LOW': 'LOW',
    'MEDIUM': 'MEDIUM',
    'HIGH': 'HIGH'
  };
  var DEFAULT_RISK_GRADE_MAP = (_DEFAULT_RISK_GRADE_M = {}, (0, _defineProperty2.default)(_DEFAULT_RISK_GRADE_M, RISK_LEVELS_MAP.LOW, {
    from: 1,
    to: 2
  }), (0, _defineProperty2.default)(_DEFAULT_RISK_GRADE_M, RISK_LEVELS_MAP.MEDIUM, {
    from: 3,
    to: 4
  }), (0, _defineProperty2.default)(_DEFAULT_RISK_GRADE_M, RISK_LEVELS_MAP.HIGH, {
    from: 5,
    to: 5
  }), _DEFAULT_RISK_GRADE_M);
  var LABEL_CLASS_MAP = (_LABEL_CLASS_MAP = {}, (0, _defineProperty2.default)(_LABEL_CLASS_MAP, RISK_LEVELS_MAP.LOW, 'badge-green'), (0, _defineProperty2.default)(_LABEL_CLASS_MAP, RISK_LEVELS_MAP.MEDIUM, 'badge-yellow'), (0, _defineProperty2.default)(_LABEL_CLASS_MAP, RISK_LEVELS_MAP.HIGH, 'badge-red'), _LABEL_CLASS_MAP);
  function badgeScoreLabel(_ref /*, hash*/) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
      scoreValue = _ref2[0];
    var calculateGradeLevel = function calculateGradeLevel() {
      var result = null;
      if (Ember.isNone(scoreValue)) return result;
      Object.keys(DEFAULT_RISK_GRADE_MAP).forEach(function (grade) {
        var _DEFAULT_RISK_GRADE_M2 = DEFAULT_RISK_GRADE_MAP[grade],
          from = _DEFAULT_RISK_GRADE_M2.from,
          to = _DEFAULT_RISK_GRADE_M2.to;
        var score = Number(scoreValue);
        if (score >= from && score <= to) {
          result = LABEL_CLASS_MAP[grade];
        }
      });
      return result;
    };
    return calculateGradeLevel();
  }
  var _default = Ember.Helper.helper(badgeScoreLabel);
  _exports.default = _default;
});
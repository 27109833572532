define("zerorisk-frontend/cldrs/sl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*jslint eqeq: true*/
  var _default = [{
    "locale": "sl",
    "pluralRuleFunction": function pluralRuleFunction(n, ord) {
      var s = String(n).split("."),
        i = s[0],
        v0 = !s[1],
        i100 = i.slice(-2);
      if (ord) return "other";
      return v0 && i100 == 1 ? "one" : v0 && i100 == 2 ? "two" : v0 && (i100 == 3 || i100 == 4) || !v0 ? "few" : "other";
    },
    "fields": {
      "year": {
        "displayName": "leto",
        "relative": {
          "0": "letos",
          "1": "naslednje leto",
          "-1": "lani"
        },
        "relativeTime": {
          "future": {
            "one": "čez {0} leto",
            "two": "čez {0} leti",
            "few": "čez {0} leta",
            "other": "čez {0} let"
          },
          "past": {
            "one": "pred {0} letom",
            "two": "pred {0} letoma",
            "few": "pred {0} leti",
            "other": "pred {0} leti"
          }
        }
      },
      "year-short": {
        "displayName": "leto",
        "relative": {
          "0": "letos",
          "1": "naslednje leto",
          "-1": "lani"
        },
        "relativeTime": {
          "future": {
            "one": "čez {0} leto",
            "two": "čez {0} leti",
            "few": "čez {0} leta",
            "other": "čez {0} let"
          },
          "past": {
            "one": "pred {0} letom",
            "two": "pred {0} letoma",
            "few": "pred {0} leti",
            "other": "pred {0} leti"
          }
        }
      },
      "month": {
        "displayName": "mesec",
        "relative": {
          "0": "ta mesec",
          "1": "naslednji mesec",
          "-1": "prejšnji mesec"
        },
        "relativeTime": {
          "future": {
            "one": "čez {0} mesec",
            "two": "čez {0} meseca",
            "few": "čez {0} mesece",
            "other": "čez {0} mesecev"
          },
          "past": {
            "one": "pred {0} mesecem",
            "two": "pred {0} mesecema",
            "few": "pred {0} meseci",
            "other": "pred {0} meseci"
          }
        }
      },
      "month-short": {
        "displayName": "mes.",
        "relative": {
          "0": "ta mesec",
          "1": "naslednji mesec",
          "-1": "prejšnji mesec"
        },
        "relativeTime": {
          "future": {
            "one": "čez {0} mes.",
            "two": "čez {0} mes.",
            "few": "čez {0} mes.",
            "other": "čez {0} mes."
          },
          "past": {
            "one": "pred {0} mes.",
            "two": "pred {0} mes.",
            "few": "pred {0} mes.",
            "other": "pred {0} mes."
          }
        }
      },
      "day": {
        "displayName": "dan",
        "relative": {
          "0": "danes",
          "1": "jutri",
          "2": "pojutrišnjem",
          "-2": "predvčerajšnjim",
          "-1": "včeraj"
        },
        "relativeTime": {
          "future": {
            "one": "čez {0} dan",
            "two": "čez {0} dneva",
            "few": "čez {0} dni",
            "other": "čez {0} dni"
          },
          "past": {
            "one": "pred {0} dnevom",
            "two": "pred {0} dnevoma",
            "few": "pred {0} dnevi",
            "other": "pred {0} dnevi"
          }
        }
      },
      "day-short": {
        "displayName": "dan",
        "relative": {
          "0": "danes",
          "1": "jutri",
          "2": "pojutrišnjem",
          "-2": "predvčerajšnjim",
          "-1": "včeraj"
        },
        "relativeTime": {
          "future": {
            "one": "čez {0} dan",
            "two": "čez {0} dneva",
            "few": "čez {0} dni",
            "other": "čez {0} dni"
          },
          "past": {
            "one": "pred {0} dnevom",
            "two": "pred {0} dnevoma",
            "few": "pred {0} dnevi",
            "other": "pred {0} dnevi"
          }
        }
      },
      "hour": {
        "displayName": "ura",
        "relative": {
          "0": "v tej uri"
        },
        "relativeTime": {
          "future": {
            "one": "čez {0} uro",
            "two": "čez {0} uri",
            "few": "čez {0} ure",
            "other": "čez {0} ur"
          },
          "past": {
            "one": "pred {0} uro",
            "two": "pred {0} urama",
            "few": "pred {0} urami",
            "other": "pred {0} urami"
          }
        }
      },
      "hour-short": {
        "displayName": "ura",
        "relative": {
          "0": "v tej uri"
        },
        "relativeTime": {
          "future": {
            "one": "čez {0} uro",
            "two": "čez {0} uri",
            "few": "čez {0} ure",
            "other": "čez {0} ur"
          },
          "past": {
            "one": "pred {0} uro",
            "two": "pred {0} urama",
            "few": "pred {0} urami",
            "other": "pred {0} urami"
          }
        }
      },
      "minute": {
        "displayName": "minuta",
        "relative": {
          "0": "to minuto"
        },
        "relativeTime": {
          "future": {
            "one": "čez {0} minuto",
            "two": "čez {0} minuti",
            "few": "čez {0} minute",
            "other": "čez {0} minut"
          },
          "past": {
            "one": "pred {0} minuto",
            "two": "pred {0} minutama",
            "few": "pred {0} minutami",
            "other": "pred {0} minutami"
          }
        }
      },
      "minute-short": {
        "displayName": "min.",
        "relative": {
          "0": "to minuto"
        },
        "relativeTime": {
          "future": {
            "one": "čez {0} min.",
            "two": "čez {0} min.",
            "few": "čez {0} min.",
            "other": "čez {0} min."
          },
          "past": {
            "one": "pred {0} min.",
            "two": "pred {0} min.",
            "few": "pred {0} min.",
            "other": "pred {0} min."
          }
        }
      },
      "second": {
        "displayName": "sekunda",
        "relative": {
          "0": "zdaj"
        },
        "relativeTime": {
          "future": {
            "one": "čez {0} sekundo",
            "two": "čez {0} sekundi",
            "few": "čez {0} sekunde",
            "other": "čez {0} sekund"
          },
          "past": {
            "one": "pred {0} sekundo",
            "two": "pred {0} sekundama",
            "few": "pred {0} sekundami",
            "other": "pred {0} sekundami"
          }
        }
      },
      "second-short": {
        "displayName": "sek.",
        "relative": {
          "0": "zdaj"
        },
        "relativeTime": {
          "future": {
            "one": "čez {0} s",
            "two": "čez {0} s",
            "few": "čez {0} s",
            "other": "čez {0} s"
          },
          "past": {
            "one": "pred {0} s",
            "two": "pred {0} s",
            "few": "pred {0} s",
            "other": "pred {0} s"
          }
        }
      }
    }
  }];
  _exports.default = _default;
});
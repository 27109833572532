define("zerorisk-frontend/utils/constants/merchant-profile-traffic-ranks", ["exports", "@babel/runtime/helpers/esm/defineProperty"], function (_exports, _defineProperty2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MerchantProfileTrafficRanks = void 0;
  var _MerchantProfileTraff;
  var MerchantProfileTrafficRanks = {
    QRank: 'QRank',
    Majestic: 'Majestic',
    ARank: 'ARank',
    Umbrella: 'Umbrella'
  };
  _exports.MerchantProfileTrafficRanks = MerchantProfileTrafficRanks;
  var _default = (_MerchantProfileTraff = {}, (0, _defineProperty2.default)(_MerchantProfileTraff, MerchantProfileTrafficRanks.QRank, {
    friendly: 'Quantcast Rank',
    labelClass: 'label-primary'
  }), (0, _defineProperty2.default)(_MerchantProfileTraff, MerchantProfileTrafficRanks.Majestic, {
    friendly: 'Majestic Rank',
    labelClass: 'label-info'
  }), (0, _defineProperty2.default)(_MerchantProfileTraff, MerchantProfileTrafficRanks.ARank, {
    friendly: 'Alexa Rank',
    labelClass: 'label-info'
  }), (0, _defineProperty2.default)(_MerchantProfileTraff, MerchantProfileTrafficRanks.Umbrella, {
    friendly: 'Umbrella Rank',
    labelClass: 'label-warning'
  }), _MerchantProfileTraff);
  _exports.default = _default;
});
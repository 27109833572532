define("zerorisk-frontend/templates/components/pg-btn-group-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "nIjQKbNl",
    "block": "{\"symbols\":[\"dd\",\"menu\",\"&default\"],\"statements\":[[4,\"bs-dropdown\",null,[[\"closeOnMenuClick\",\"class\"],[false,[27,\"concat\",[\"d-btn-group \",[27,\"or\",[[23,[\"class\"]],\"\"],null]],null]]],{\"statements\":[[0,\"  \"],[1,[27,\"bs-button\",null,[[\"type\",\"class\",\"defaultText\",\"pendingText\",\"rejectedText\",\"onClick\"],[[23,[\"buttonType\"]],[23,[\"buttonClasses\"]],[23,[\"buttonText\"]],[27,\"or\",[[23,[\"pendingText\"]],[27,\"t\",[\"app.downloading\"],null]],null],[27,\"or\",[[23,[\"rejectedText\"]],[27,\"t\",[\"error.whoops_something_wrong\"],null]],null],[27,\"action\",[[22,0,[]],[23,[\"onClick\"]]],null]]]],false],[0,\"\\n\\n\"],[4,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"button\"]],\"expected `dd.button` to be a contextual component but found a string. Did you mean `(component dd.button)`? ('zerorisk-frontend/templates/components/pg-btn-group-dropdown.hbs' @ L11:C5) \"],null]],[[\"class\",\"type\"],[[23,[\"ddButtonClass\"]],[23,[\"buttonType\"]]]],{\"statements\":[[0,\"    \"],[7,\"i\"],[12,\"class\",[28,[\"fa fa-\",[21,\"dropdownIcon\"]]]],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"menu\"]],\"expected `dd.menu` to be a contextual component but found a string. Did you mean `(component dd.menu)`? ('zerorisk-frontend/templates/components/pg-btn-group-dropdown.hbs' @ L15:C5) \"],null]],null,{\"statements\":[[0,\"    \"],[14,3,[[22,2,[]]]],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/pg-btn-group-dropdown.hbs"
    }
  });
  _exports.default = _default;
});
define("zerorisk-frontend/templates/components/inline-server-errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "QxVLXSQ+",
    "block": "{\"symbols\":[\"error\",\"@errors\"],\"statements\":[[4,\"if\",[[22,2,[]]],null,{\"statements\":[[4,\"each\",[[22,2,[]]],null,{\"statements\":[[0,\"\\t    \"],[7,\"div\"],[11,\"class\",\"validation-error\"],[9],[0,\"\\n\\t      \"],[7,\"span\"],[9],[0,\"\\n\\t        \"],[7,\"i\"],[11,\"class\",\"material-icons left\"],[9],[1,[27,\"t\",[\"error.warning\"],null],false],[10],[0,\"\\n\\t        \"],[7,\"span\"],[9],[1,[27,\"t\",[\"error.this_field\"],null],false],[0,\" \"],[1,[22,1,[\"message\"]],false],[10],[0,\"\\n\\t      \"],[10],[0,\"\\n\\t    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/inline-server-errors.hbs"
    }
  });
  _exports.default = _default;
});
define("zerorisk-frontend/templates/legal/terms-of-use", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "D3BL3dfF",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"content sm-gutter\"],[11,\"style\",\"height: 90%;\"],[9],[0,\"\\n\\t\"],[7,\"div\"],[11,\"class\",\"container-fluid container-fixed-lg full-height\"],[11,\"style\",\"width: 100%;\"],[9],[0,\"\\n\\t\\t\"],[7,\"iframe\"],[12,\"src\",[21,\"url\"]],[11,\"style\",\"border: none; width: 100%; height: 100%;\"],[9],[10],[0,\"\\n\\t\"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/legal/terms-of-use.hbs"
    }
  });
  _exports.default = _default;
});
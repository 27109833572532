define("zerorisk-frontend/templates/components/form-fragment-array-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "9ixvRI72",
    "block": "{\"symbols\":[\"modelItem\",\"form\",\"fieldName\",\"@fields\",\"@showValidation\",\"@model\",\"@modelName\"],\"statements\":[[4,\"each\",[[22,6,[]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"input-group\"],[9],[0,\"\\n\"],[4,\"bs-form\",null,[[\"model\",\"showAllValidations\",\"class\"],[[22,1,[]],[22,5,[]],\"input-group\"]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[22,4,[]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,2,[\"element\"]],\"expected `form.element` to be a contextual component but found a string. Did you mean `(component form.element)`? ('zerorisk-frontend/templates/components/form-fragment-array-editor.hbs' @ L10:C10) \"],null]],[[\"controlType\",\"placeholder\",\"property\",\"class\",\"required\"],[\"text\",[27,\"t\",[[27,\"concat\",[\"app.\",[22,3,[]]],null]],null],[22,3,[]],\"input-group-element-container\",true]]],false],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"      \"],[7,\"a\"],[11,\"class\",\"btn no-border m-b-10\"],[11,\"style\",\"height: 35px;\"],[9],[0,\"\\n        \"],[7,\"i\"],[11,\"class\",\"fa fa-trash\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n      \"],[3,\"action\",[[22,0,[]],\"remove\",[22,1,[]]]],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[7,\"div\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"btn btn-rounded btn-info btn-m-t-10\"],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"fa fa-plus m-r-5\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n    \"],[1,[27,\"t\",[\"app.add\"],null],false],[0,\"\\n    \"],[1,[22,7,[]],false],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"add\"]],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/form-fragment-array-editor.hbs"
    }
  });
  _exports.default = _default;
});
define("zerorisk-frontend/utils/helpers/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.matchRoute = void 0;
  var matchRoute = function matchRoute(routes, currentRoute) {
    return routes.some(function (route) {
      return currentRoute && currentRoute.includes(route);
    });
  };
  _exports.matchRoute = matchRoute;
});
define("zerorisk-frontend/templates/components/audits/survey-question/yes-no-radio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "flSFfaay",
    "block": "{\"symbols\":[\"@handleAnswer\"],\"statements\":[[7,\"div\"],[11,\"class\",\"list-group-item border-left-0 border-right-0\"],[11,\"style\",\"border-bottom: 1px solid #ECECEC; z-index: 0;\"],[9],[0,\"\\n  \"],[1,[27,\"t\",[[23,[\"surveyQuestion\",\"text\"]]],null],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"list-group-item border-left-0 border-right-0 border-bottom-0 text-right\"],[11,\"style\",\"background-color: #FDFDFD;\"],[9],[0,\"\\n  \"],[7,\"img\"],[11,\"src\",\"/assets/img/speech-bubble-piece.png\"],[11,\"style\",\"height: 28px; margin-top: -21px; padding-right: 10px;\"],[9],[10],[0,\"\\n    \"],[7,\"span\"],[11,\"style\",\"margin-right: 10px;\"],[9],[0,\"\\n      \"],[7,\"input\"],[12,\"name\",[28,[\"group-survey-\",[23,[\"surveyQuestion\",\"questionId\"]]]]],[12,\"id\",[28,[\"radio-\",[23,[\"surveyQuestion\",\"questionId\"]],\"-yes\"]]],[12,\"onchange\",[27,\"action\",[[22,0,[]],[22,1,[]],[27,\"hash\",null,[[\"id\",\"answer\"],[[23,[\"surveyQuestion\",\"questionId\"]],\"1\"]]]],null]],[11,\"type\",\"radio\"],[9],[10],[0,\"\\n      \"],[7,\"label\"],[12,\"for\",[28,[\"radio-\",[23,[\"surveyQuestion\",\"questionId\"]],\"-yes\"]]],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"app.button_yes\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"span\"],[9],[0,\"\\n      \"],[7,\"input\"],[12,\"name\",[28,[\"group-survey-\",[23,[\"surveyQuestion\",\"questionId\"]]]]],[12,\"id\",[28,[\"radio-\",[23,[\"surveyQuestion\",\"questionId\"]],\"-no\"]]],[12,\"onchange\",[27,\"action\",[[22,0,[]],[22,1,[]],[27,\"hash\",null,[[\"id\",\"answer\"],[[23,[\"surveyQuestion\",\"questionId\"]],\"0\"]]]],null]],[11,\"type\",\"radio\"],[9],[10],[0,\"\\n      \"],[7,\"label\"],[12,\"for\",[28,[\"radio-\",[23,[\"surveyQuestion\",\"questionId\"]],\"-no\"]]],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"app.button_no\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/audits/survey-question/yes-no-radio.hbs"
    }
  });
  _exports.default = _default;
});
define("zerorisk-frontend/templates/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "jKsl0wZr",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"container p-t-30\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"card card-default box-shadow-spread padding-20\"],[9],[0,\"\\n      \"],[7,\"h3\"],[11,\"class\",\"bold\"],[9],[0,\"\\n        \"],[7,\"i\"],[11,\"class\",\"fa fa-binoculars m-r-5\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n        \"],[1,[27,\"t\",[\"error.404_where_am_i\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"h5\"],[11,\"class\",\"bold hint-text\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"error.404_alone\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"h5\"],[11,\"class\",\"bold\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"error.404_not_found\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n\"],[0,\"      \"],[7,\"div\"],[11,\"class\",\"row padding-10 p-r-20 d-flex ml-auto\"],[9],[0,\"\\n        \"],[7,\"a\"],[12,\"href\",[27,\"href-to\",[\"dashboard\"],null]],[11,\"class\",\"m-l-5 btn btn-lg btn-success btn-rounded bold\"],[9],[0,\"\\n          \"],[1,[27,\"t\",[\"app.go_home\"],null],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/not-found.hbs"
    }
  });
  _exports.default = _default;
});
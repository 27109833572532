define("zerorisk-frontend/templates/components/subscription-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ojhdjHz4",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[22,0,[\"isShowing\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[28,[\"alert alert-warning bordered d-flex \",[21,\"class\"]]]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"flex-grow-1 d-flex align-items-center\"],[9],[0,\"\\n      \"],[7,\"div\"],[9],[0,\"\\n         \"],[7,\"i\"],[11,\"class\",\"fa fa-warning m-r-5\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\" \"],[1,[27,\"t\",[\"subscription.has_expired\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[9],[0,\"\\n      \"],[7,\"a\"],[12,\"href\",[27,\"href-to\",[\"subscription.renew\"],null]],[11,\"class\",\"btn btn-rounded bold btn-info\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"subscription.action_renew\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/subscription-alert.hbs"
    }
  });
  _exports.default = _default;
});
define("zerorisk-frontend/templates/components/generic/saq-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "05VV9obt",
    "block": "{\"symbols\":[\"&default\",\"@saq\"],\"statements\":[[7,\"span\"],[11,\"class\",\"text-nowrap\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"fs-12 label no-border-right\"],[11,\"style\",\"padding-right: 4px;\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"\"],[9],[1,[27,\"enum-to-friendly\",[[22,2,[\"type\"]]],null],false],[0,\" \"],[1,[22,2,[\"version\"]],false],[0,\" \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"span\"],[12,\"class\",[28,[\"fs-12 label \",[27,\"enum-to-friendly\",[[22,2,[\"status\"]],\"labelClass\"],null],\" \",[27,\"if\",[[22,2,[\"showExpirationDate\"]],\"no-border-right\"],null],\" no-border-left\"]]],[9],[0,\"\\n\"],[4,\"if\",[[22,2,[\"status\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"t\",[[27,\"concat\",[\"enums.saq_status.\",[22,2,[\"status\"]]],null]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[4,\"if\",[[22,2,[\"showExpirationDate\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"fs-12 label no-border-left\"],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"fa fa-clock-o\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\" \"],[1,[27,\"moment-format\",[[22,2,[\"expirationDate\"]],\"DD/MM/YYYY\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/generic/saq-label.hbs"
    }
  });
  _exports.default = _default;
});
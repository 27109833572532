define("zerorisk-frontend/templates/saqs/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "g/UU//nX",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n  \"],[1,[27,\"pg-navbar\",null,[[\"symbol\"],[\"edit\"]]],false],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"container container-fluid m-t-20\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row bg-white\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col-md-12\"],[9],[0,\"\\n\"],[4,\"if\",[[22,0,[\"customization\",\"mpa\",\"useWizardV2\"]]],null,{\"statements\":[[0,\"          \"],[1,[27,\"questionnaire/text-wizard\",null,[[\"wizardId\"],[[22,0,[\"customization\",\"mpa\",\"wizardId\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[27,\"text-wizard\",null,[[\"wizardId\"],[[22,0,[\"customization\",\"mpa\",\"wizardId\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/saqs/new.hbs"
    }
  });
  _exports.default = _default;
});
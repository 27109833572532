define("zerorisk-frontend/templates/dashboard-mpa", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Qo+vLrTu",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"dashboard-mpa/index\",null,[[\"transitionToMerchants\"],[[27,\"action\",[[22,0,[]],\"transitionToMerchants\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/dashboard-mpa.hbs"
    }
  });
  _exports.default = _default;
});
define("zerorisk-frontend/helpers/enum-to-friendly", ["exports", "@babel/runtime/helpers/esm/extends", "zerorisk-frontend/utils/constants"], function (_exports, _extends2, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.enumToFriendly = enumToFriendly;
  var Enums = (0, _extends2.default)({}, _constants.BusinessChannels, _constants.DeviceStatuses, _constants.DevicesTypes, _constants.FileTypes, _constants.FileUploaderConstants, _constants.Frequencies, _constants.Levels, _constants.MerchantStatues, _constants.SaqStatues, _constants.SaqTypes, _constants.ScanStatues, _constants.RoleTypes, _constants.MerchantProfileTrafficRanks, _constants.CompromiseStatuses, _constants.CompromiseWorkflowTypes, _constants.CompromiseTaskStatuses, _constants.CompromiseTaskCategorykeys);
  function enumToFriendly(params) {
    var value = params[0],
      key = params[1] || 'friendly';
    return value && Enums[value] && Enums[value][key] ? Enums[value][key] : value;
  }
  var _default = Ember.Helper.helper(enumToFriendly);
  _exports.default = _default;
});
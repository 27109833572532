define("zerorisk-frontend/templates/components/modals/questionnaire/text-wizard/answer-hint", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "cdJ8GQiX",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ember-remodal\",null,[[\"forService\",\"name\"],[true,\"answer-hint\"]],{\"statements\":[[0,\"  \"],[7,\"header\"],[11,\"class\",\"modal-header\"],[9],[0,\"\\n    \"],[7,\"h1\"],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"fa fa-info-circle m-r-5\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n      \"],[1,[27,\"t\",[\"app.information\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"modal-body rounded-b-corners\"],[9],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"p-t-10 p-b-25\"],[9],[0,\"\\n        \"],[1,[22,0,[\"answerHint\",\"description\"]],true],[0,\"\\n      \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/modals/questionnaire/text-wizard/answer-hint.hbs"
    }
  });
  _exports.default = _default;
});
define("zerorisk-frontend/templates/components/file-tools/existing-saq/uploader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "QQXL0NN0",
    "block": "{\"symbols\":[\"@entityType\"],\"statements\":[[4,\"unless\",[[22,0,[\"filePendingToUpload\"]]],null,{\"statements\":[[4,\"file-tools/uploader\",null,[[\"onfileadd\"],[[27,\"action\",[[22,0,[]],\"onfileadd\"],null]]],{\"statements\":[[0,\"\\n    \"],[7,\"h3\"],[11,\"class\",\"text-center file-uploader-title\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"files.newfile.upload_existing_saq\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[27,\"file-tools/existing-saq/editor\",null,[[\"filePendingToUpload\",\"entityType\",\"onSubmit\",\"onCancel\"],[[22,0,[\"filePendingToUpload\"]],[22,1,[]],[27,\"action\",[[22,0,[]],\"saveFile\"],null],[27,\"action\",[[22,0,[]],\"cancel\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/file-tools/existing-saq/uploader.hbs"
    }
  });
  _exports.default = _default;
});
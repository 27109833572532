define("zerorisk-frontend/helpers/inline-svg", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-inline-svg/helpers/inline-svg", "zerorisk-frontend/svgs"], function (_exports, _slicedToArray2, _inlineSvg, _svgs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var helper;
  if (Ember.Helper && Ember.Helper.helper) {
    helper = Ember.Helper.helper(function (_ref, options) {
      var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        path = _ref2[0];
      return (0, _inlineSvg.inlineSvg)(_svgs.default, path, options);
    });
  } else {
    helper = Ember.Handlebars.makeBoundHelper(function (path, options) {
      return (0, _inlineSvg.inlineSvg)(_svgs.default, path, options.hash || {});
    });
  }
  var _default = helper;
  _exports.default = _default;
});
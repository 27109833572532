define("zerorisk-frontend/templates/registration-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "BGxQqrMY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n    \"],[1,[21,\"loading-spinner\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/registration-loading.hbs"
    }
  });
  _exports.default = _default;
});
define("zerorisk-frontend/utils/types/notifications", ["exports", "zerorisk-frontend/utils/types/-internal"], function (_exports, _internal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.NotificationWarningTypes = _exports.NotificationTypes = _exports.NotificationStatuses = void 0;
  var NotificationWarningTypes = _internal.default.create({
    SCAN_FAILED: 'SCAN_FAILED',
    SAQ_EXPIRATION_YEAR: 'SAQ_EXPIRATION_YEAR',
    SAQ_EXPIRATION_EARLY: 'SAQ_EXPIRATION_EARLY',
    ASSET_IP_EXPIRATION_EARLY: 'ASSET_IP_EXPIRATION_EARLY',
    ASSET_IP_EXPIRATION_FINAL: 'ASSET_IP_EXPIRATION_FINAL',
    USER_PASSWORD_EXPIRATION_EARLY: 'USER_PASSWORD_EXPIRATION_EARLY',
    USER_PASSWORD_EXPIRATION_FINAL: 'USER_PASSWORD_EXPIRATION_FINAL',
    PINPOINT_QUICK_AUDIT_FAIL: 'PINPOINT_QUICK_AUDIT_FAIL'
  });
  _exports.NotificationWarningTypes = NotificationWarningTypes;
  var NotificationTypes = _internal.default.create(NotificationWarningTypes, {
    MESSAGE_RECEIVED: 'MESSAGE_RECEIVED'
  });
  _exports.NotificationTypes = NotificationTypes;
  var NotificationStatuses = _internal.default.create({
    NEW: 'NEW'
  });
  _exports.NotificationStatuses = NotificationStatuses;
});
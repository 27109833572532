define("zerorisk-frontend/templates/admin/users/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "/RMsWWVw",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n  \"],[1,[27,\"containers/admin/users/users-index\",null,[[\"users\",\"currentlyLoading\",\"isLoading\",\"total\",\"searchQuery\"],[[22,0,[\"model\",\"data\"]],[22,0,[\"currentlyLoading\"]],[22,0,[\"model\",\"data\",\"loading\"]],[22,0,[\"model\",\"data\",\"meta\",\"total\"]],[22,0,[\"debounce_searchQuery\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[22,0,[\"model\",\"data\",\"length\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"d-flex justify-content-center m-t-10 m-b-10\"],[9],[0,\"\\n        \"],[1,[27,\"page-numbers\",null,[[\"content\"],[[22,0,[\"model\",\"data\"]]]]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/admin/users/index.hbs"
    }
  });
  _exports.default = _default;
});
define("zerorisk-frontend/templates/components/loading-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "OnznsobO",
    "block": "{\"symbols\":[\"@text\",\"@containerClass\"],\"statements\":[[7,\"div\"],[11,\"style\",\"padding-top: 30px; height: 100%; background-color: white;\"],[12,\"class\",[22,2,[]]],[9],[0,\"\\n  \"],[7,\"i\"],[11,\"class\",\"fa fa-spinner fa-spin fa-3x fa-fw text-master-light\"],[11,\"style\",\"margin: auto; display: block;\"],[9],[10],[0,\"\\n\\n\"],[4,\"if\",[[22,1,[]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"center-align grey-text text-darken-2 m-t-5\"],[9],[1,[22,1,[]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/loading-spinner.hbs"
    }
  });
  _exports.default = _default;
});
define("zerorisk-frontend/templates/components/generic/toggle-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "97Ts+slN",
    "block": "{\"symbols\":[\"@checked\",\"@change\"],\"statements\":[[7,\"span\"],[12,\"class\",[28,[\"toggle-switch \",[27,\"if\",[[22,1,[]],\"active\"],null]]]],[9],[0,\"\\n  \"],[7,\"small\"],[9],[10],[0,\"\\n\"],[3,\"action\",[[22,0,[]],[22,2,[]]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/generic/toggle-switch.hbs"
    }
  });
  _exports.default = _default;
});
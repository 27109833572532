define("zerorisk-frontend/templates/locations-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "kUT22WKe",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"loading-spinner\",null,[[\"class\"],[\"m-t-60\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/locations-loading.hbs"
    }
  });
  _exports.default = _default;
});
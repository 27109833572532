define("zerorisk-frontend/templates/components/modals/terms-and-conditions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xjT7tTNk",
    "block": "{\"symbols\":[\"@onAgree\"],\"statements\":[[4,\"ember-remodal\",null,[[\"forService\",\"name\"],[true,\"terms-and-conditions\"]],{\"statements\":[[0,\"  \"],[7,\"header\"],[11,\"class\",\"modal-header\"],[9],[0,\"\\n    \"],[7,\"h1\"],[11,\"class\",\"font-montserrat text-uppercase\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"enrollment.welcome.terms_and_conditions_part_2\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"modal-body\"],[11,\"style\",\"height: 400px;\"],[9],[0,\"\\n    \"],[7,\"iframe\"],[12,\"src\",[21,\"url\"]],[11,\"style\",\"border: none; width: 100%; height: 100%;\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[22,1,[]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"modal-footer m-t-10 m-b-10\"],[9],[0,\"\\n    \\t\"],[7,\"button\"],[11,\"class\",\"btn btn-complete\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"app.agree\"],null],false],[0,\"\\n      \"],[3,\"action\",[[22,0,[]],\"agree\"]],[10],[0,\"\\n    \\t\"],[7,\"button\"],[11,\"class\",\"btn transparent no-depth no-border\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"app.button_cancel\"],null],false],[0,\"\\n      \"],[3,\"action\",[[22,0,[]],\"close\"]],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/modals/terms-and-conditions.hbs"
    }
  });
  _exports.default = _default;
});
define("zerorisk-frontend/templates/components/ip-reveal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "8NAGPoix",
    "block": "{\"symbols\":[\"@buttonText\",\"@buttonClasses\"],\"statements\":[[4,\"unless\",[[22,0,[\"copyToClipboard\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"button\"],[12,\"class\",[22,2,[]]],[9],[1,[22,1,[]],false],[3,\"action\",[[22,0,[]],\"getIp\"]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"copy-button\",null,[[\"class\",\"clipboardText\",\"disabled\",\"success\",\"error\"],[[22,2,[]],[22,0,[\"currentIp\"]],[27,\"not\",[[22,0,[\"currentIp\"]]],null],[27,\"action\",[[22,0,[]],\"success\"],null],[27,\"action\",[[22,0,[]],\"error\"],null]]],{\"statements\":[[4,\"if\",[[22,0,[\"error\"]]],null,{\"statements\":[[0,\"\\t\\t\\tCouldn't reveal IP\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"unless\",[[22,0,[\"currentIp\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\tDetecting IP\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\\t\"],[1,[22,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"],[4,\"bs-tooltip\",null,[[\"placement\",\"visible\",\"triggerEvents\"],[\"top\",[22,0,[\"showCopiedTooltip\"]],\"none\"]],{\"statements\":[[0,\"\\t  \\tCopied\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/ip-reveal.hbs"
    }
  });
  _exports.default = _default;
});
define("zerorisk-frontend/mixins/sweetalert-mixin", ["exports", "sweetalert2"], function (_exports, _sweetalert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    sweetAlert: _sweetalert.default
  });
  _exports.default = _default;
});
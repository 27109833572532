define("zerorisk-frontend/templates/components/questionnaire/text-wizard/none-answer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "KQ5A3fmJ",
    "block": "{\"symbols\":[\"answer\",\"@currentQuestion\",\"@answer\",\"@currentAnswer\"],\"statements\":[[7,\"div\"],[11,\"class\",\"clearfix\"],[11,\"style\",\"width: 50%; text-align: center; margin: 0 auto;\"],[9],[0,\"\\n\"],[4,\"each\",[[22,4,[\"branches\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\"],[11,\"class\",\"btn btn-complete-green btn-lg btn-larger btn-xxl bold\"],[11,\"width\",\"150px\"],[9],[0,\"\\n        \"],[1,[22,1,[\"text\"]],false],[0,\"\\n\"],[0,\"    \"],[3,\"action\",[[22,0,[]],[27,\"pipe\",[[27,\"action\",[[22,0,[]],[27,\"mut\",[[22,1,[\"selected\"]]],null],true],null],[27,\"action\",[[22,0,[]],[22,3,[]],[22,1,[\"bringsTo\"]]],null]],null]]],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\\n\"],[4,\"if\",[[22,2,[\"info\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"text-wizard-question-container container-fluid p-b-50\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"hint-text all-caps fs-10 font-montserrat\"],[11,\"style\",\"width: 80%; margin: 0 auto; margin-top: 15px; margin-bottom: 3px;\"],[9],[1,[27,\"t\",[\"app.info\"],null],false],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"fs-13\"],[11,\"style\",\"width: 80%; margin: 0 auto;\"],[9],[0,\"\\n        \"],[1,[22,2,[\"info\"]],true],[0,\"\\n      \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/questionnaire/text-wizard/none-answer.hbs"
    }
  });
  _exports.default = _default;
});
define("zerorisk-frontend/templates/components/generic/no-records", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "dBJAfoEk",
    "block": "{\"symbols\":[\"@title\",\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"m-b-20\"],[9],[0,\"\\n  \"],[7,\"i\"],[11,\"class\",\"fa fa-3x fa-frown-o hint-text\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n  \"],[7,\"h4\"],[11,\"class\",\"hint-text\"],[9],[1,[22,1,[]],false],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[14,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/generic/no-records.hbs"
    }
  });
  _exports.default = _default;
});
define("zerorisk-frontend/templates/components/questionnaire/text-wizard/multiple-answer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "dB36lwzd",
    "block": "{\"symbols\":[\"@openAnswerHint\",\"@answer\",\"@currentQuestion\",\"@currentAnswer\"],\"statements\":[[4,\"if\",[[22,0,[\"viewComponent\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"component\",[[22,0,[\"viewComponent\"]]],[[\"currentAnswer\",\"currentQuestion\",\"answer\",\"exclusive\",\"openAnswerHint\",\"calculateChoiceImgDropdownPosition\"],[[22,4,[]],[22,3,[]],[22,2,[]],[22,0,[\"exclusive\"]],[22,1,[]],[22,0,[\"calculateChoiceImgDropdownPosition\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/questionnaire/text-wizard/multiple-answer.hbs"
    }
  });
  _exports.default = _default;
});
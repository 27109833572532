define("zerorisk-frontend/templates/components/text-wizard/multiple-answer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "3cOz7RW6",
    "block": "{\"symbols\":[\"@answer\",\"@answers\"],\"statements\":[[4,\"if\",[[22,0,[\"viewComponent\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"component\",[[22,0,[\"viewComponent\"]]],[[\"answers\",\"answer\",\"exclusive\",\"calculateChoiceImgDropdownPosition\",\"openSupport\"],[[22,2,[]],[22,1,[]],[22,0,[\"exclusive\"]],[22,0,[\"calculateChoiceImgDropdownPosition\"]],[27,\"action\",[[22,0,[]],\"openSupport\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/text-wizard/multiple-answer.hbs"
    }
  });
  _exports.default = _default;
});
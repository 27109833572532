define("zerorisk-frontend/templates/components/file-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "pPH+XFDC",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"dropzone\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[28,[\"file-picker__dropzone \",[27,\"if\",[[23,[\"complete\"]],\"no-border file-picker__backdrop\"],null]]]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"imagePreviewSrc\"]]],null,{\"statements\":[[0,\"      \"],[7,\"img\"],[12,\"src\",[21,\"imagePreviewSrc\"]],[12,\"class\",[28,[\"file-picker__preview_img \",[27,\"if\",[[23,[\"isUploading\"]],\"opacity-2\",\"opacity-8\"],null]]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[7,\"input\"],[12,\"value\",[21,\"file\"]],[12,\"accept\",[21,\"accept\"]],[12,\"multiple\",[21,\"multiple\"]],[11,\"class\",\"file-picker__input\"],[11,\"type\",\"file\"],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/file-picker.hbs"
    }
  });
  _exports.default = _default;
});
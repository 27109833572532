define("zerorisk-frontend/initializers/metrics", ["exports", "zerorisk-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {
    var application = arguments[1] || arguments[0];
    var _config$metricsAdapte = _environment.default.metricsAdapters,
      metricsAdapters = _config$metricsAdapte === void 0 ? [] : _config$metricsAdapte;
    var _config$environment = _environment.default.environment,
      environment = _config$environment === void 0 ? 'development' : _config$environment;
    var options = {
      metricsAdapters: metricsAdapters,
      environment: environment
    };
    application.register('config:metrics', options, {
      instantiate: false
    });
    application.inject('service:metrics', 'options', 'config:metrics');
  }
  var _default = {
    name: 'metrics',
    initialize: initialize
  };
  _exports.default = _default;
});
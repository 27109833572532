define("zerorisk-frontend/helpers/get-grade-score-label", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "@babel/runtime/helpers/esm/defineProperty"], function (_exports, _slicedToArray2, _defineProperty2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getGradeScoreLabel = getGradeScoreLabel;
  var _DEFAULT_RISK_GRADE_M;
  var RISK_LEVELS_MAP = {
    TRUSTED: 'TRUSTED',
    LOW: 'LOW',
    MEDIUM: 'MEDIUM',
    HIGH: 'HIGH',
    CRITICAL: 'CRITICAL'
  };
  var DEFAULT_RISK_GRADE_MAP = (_DEFAULT_RISK_GRADE_M = {}, (0, _defineProperty2.default)(_DEFAULT_RISK_GRADE_M, RISK_LEVELS_MAP.TRUSTED, {
    from: 91,
    to: 100
  }), (0, _defineProperty2.default)(_DEFAULT_RISK_GRADE_M, RISK_LEVELS_MAP.LOW, {
    from: 81,
    to: 90
  }), (0, _defineProperty2.default)(_DEFAULT_RISK_GRADE_M, RISK_LEVELS_MAP.MEDIUM, {
    from: 51,
    to: 80
  }), (0, _defineProperty2.default)(_DEFAULT_RISK_GRADE_M, RISK_LEVELS_MAP.HIGH, {
    from: 21,
    to: 50
  }), (0, _defineProperty2.default)(_DEFAULT_RISK_GRADE_M, RISK_LEVELS_MAP.CRITICAL, {
    from: 1,
    to: 20
  }), _DEFAULT_RISK_GRADE_M);
  function getGradeScoreLabel(_ref /*, hash*/) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
      scoreValue = _ref2[0],
      scoreType = _ref2[1];
    var calculateGradeLevel = function calculateGradeLevel() {
      var gradeMap = {
        globalTrustScore: DEFAULT_RISK_GRADE_MAP,
        globalTrustScoreOverlayed: DEFAULT_RISK_GRADE_MAP,
        paymentPropertiesTrustScore: DEFAULT_RISK_GRADE_MAP,
        paymentPropertiesTrustScoreOverlayed: DEFAULT_RISK_GRADE_MAP
      };
      var result = null;
      if (Ember.isNone(scoreValue)) return result;
      Object.keys(gradeMap[scoreType]).forEach(function (grade) {
        var _gradeMap$scoreType$g = gradeMap[scoreType][grade],
          from = _gradeMap$scoreType$g.from,
          to = _gradeMap$scoreType$g.to;
        var score = Number(scoreValue);
        if (score >= from && score <= to) {
          result = RISK_LEVELS_MAP[grade];
        }
      });
      return result;
    };
    return calculateGradeLevel();
  }
  var _default = Ember.Helper.helper(getGradeScoreLabel);
  _exports.default = _default;
});
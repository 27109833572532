define("zerorisk-frontend/templates/devices/upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "L6SvPTwL",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n  \"],[1,[27,\"pg-navbar\",null,[[\"symbol\"],[\"upload\"]]],false],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"container container-fluid m-t-20\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row bg-white\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col-md-12\"],[9],[0,\"\\n\"],[4,\"if\",[[22,0,[\"isUploadStarted\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\"],[11,\"class\",\"alert alert-info bordered d-flex\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"flex-grow-1 d-flex align-items-center\"],[9],[0,\"\\n              \"],[7,\"div\"],[9],[1,[22,0,[\"uploadDescription\"]],false],[10],[0,\"\\n            \"],[10],[0,\"\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[7,\"p\"],[11,\"class\",\"p-t-10\"],[9],[0,\"\\n          \"],[1,[27,\"file-tools/devices-uploader\",null,[[\"didFileAdd\",\"onUploadComplete\",\"onUploadError\"],[[27,\"action\",[[22,0,[]],\"didFileAdd\"],null],[27,\"action\",[[22,0,[]],\"onUploadComplete\"],null],[27,\"action\",[[22,0,[]],\"onUploadError\"],null]]]],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/devices/upload.hbs"
    }
  });
  _exports.default = _default;
});
define("zerorisk-frontend/services/actions/customization", ["exports", "zerorisk-frontend/services/actions/base-relation"], function (_exports, _baseRelation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _baseRelation.default.extend({
    recordName: 'customization',
    implementMethods: Ember.A(['update'])
  });
  _exports.default = _default;
});
define("zerorisk-frontend/templates/components/modals/update-webhook", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "lb3+y+i4",
    "block": "{\"symbols\":[\"@selectedWebhook\",\"@customization\"],\"statements\":[[4,\"ember-remodal\",null,[[\"forService\",\"name\"],[true,\"update-webhook\"]],{\"statements\":[[0,\"  \"],[7,\"header\"],[11,\"class\",\"modal-header\"],[9],[0,\"\\n    \"],[7,\"h1\"],[9],[1,[27,\"t\",[\"customization.webhooks.modals.update_webhook\"],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"modal-body rounded-b-corners p-t-40\"],[9],[0,\"\\n    \"],[1,[27,\"forms/customization/update-webhook\",null,[[\"customization\",\"webhook\",\"onComplete\"],[[22,2,[]],[22,1,[]],[27,\"action\",[[22,0,[]],\"onUpdateComplete\"],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/modals/update-webhook.hbs"
    }
  });
  _exports.default = _default;
});
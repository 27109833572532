define("zerorisk-frontend/templates/components/containers/ips/vulnerabilities-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "/YCxc+7/",
    "block": "{\"symbols\":[\"vuln\",\"index\",\"@model\"],\"statements\":[[7,\"div\"],[11,\"class\",\"container container-fixed-lg p-t-25\"],[9],[0,\"\\n\"],[4,\"each\",[[22,3,[\"vulnerabilities\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"ips/vulnerability-item\",null,[[\"vuln\",\"index\",\"attachments\",\"onUpdateVulnId\"],[[22,1,[]],[22,2,[]],[27,\"get\",[[22,0,[\"vulnAttachmentMap\"]],[22,1,[\"id\"]]],null],[27,\"action\",[[22,0,[]],\"onUpdateVulnId\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null],[10],[0,\"\\n\\n\"],[1,[27,\"modals/evidences-uploader\",null,[[\"modelType\",\"currentVulnId\",\"vulnAttachmentMap\"],[\"falsePositive\",[22,0,[\"currentVulnId\"]],[22,0,[\"vulnAttachmentMap\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/containers/ips/vulnerabilities-list.hbs"
    }
  });
  _exports.default = _default;
});
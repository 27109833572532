define("zerorisk-frontend/templates/components/generic/genesis-pci-status-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "+ZEiSe0S",
    "block": "{\"symbols\":[\"@expiry\",\"@status\"],\"statements\":[[7,\"span\"],[11,\"class\",\"text-nowrap m-r-5\"],[9],[0,\"\\n\"],[4,\"if\",[[22,2,[]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[12,\"class\",[28,[\"label label-success fs-12 \",[27,\"if\",[[22,1,[]],\"no-border-right\"],null]]]],[9],[7,\"span\"],[9],[0,\"PCI Pass \"],[10],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[22,2,[]],null],null]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"label label-primary fs-12\"],[9],[1,[27,\"t\",[\"app.not_scanned\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[22,2,[]],false],null]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"label label-primary fs-12\"],[9],[0,\"PCI Fail\"],[10],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[27,\"and\",[[22,1,[]],[22,2,[]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"fs-12 label no-border-left\"],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"fa fa-clock-o\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\" \"],[1,[27,\"moment-format\",[[22,1,[]],\"DD/MM/YYYY\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/generic/genesis-pci-status-label.hbs"
    }
  });
  _exports.default = _default;
});
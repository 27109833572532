define("zerorisk-frontend/templates/components/containers/admin/users/header-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "kvLU0pnz",
    "block": "{\"symbols\":[\"@total\",\"@searchQuery\"],\"statements\":[[7,\"div\"],[11,\"class\",\"col-sm-6 col-middle pull-right hide-sm\"],[11,\"style\",\"margin-top: 10px;\"],[9],[0,\"\\n\"],[4,\"if\",[[22,1,[]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"hint-text\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"gt\",[[22,1,[]],1],null]],null,{\"statements\":[[0,\"        \"],[1,[22,1,[]],false],[0,\" \"],[1,[27,\"t\",[\"menus.users_title\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[27,\"t\",[\"app.user\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[1,[27,\"t\",[\"app.in_total\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"col-sm-6 col-middle pull-right\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"full-width\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"input-group p-l-0 transparent b-b b-transparent pull-right\"],[11,\"style\",\"border-color: rgba(0, 0, 0, 0.03); max-width: 220px;\"],[9],[0,\"\\n      \"],[1,[27,\"input\",null,[[\"type\",\"value\",\"class\",\"placeholder\"],[\"text\",[22,2,[]],\"form-control bg-transparent no-border p-l-0 transparent\",[27,\"concat\",[[27,\"t\",[\"app.search\"],null],\" \",[27,\"t\",[\"menus.users_title\"],null]],null]]]],false],[0,\"\\n\\n      \"],[7,\"span\"],[11,\"class\",\"input-group-addon no-border\"],[9],[0,\"\\n        \"],[7,\"i\"],[11,\"class\",\"pg-search\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/containers/admin/users/header-view.hbs"
    }
  });
  _exports.default = _default;
});
define("zerorisk-frontend/utils/types/index", ["exports", "zerorisk-frontend/utils/types/invalidations", "zerorisk-frontend/utils/types/notifications"], function (_exports, _invalidations, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.keys(_invalidations).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _invalidations[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function get() {
        return _invalidations[key];
      }
    });
  });
  Object.keys(_notifications).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _notifications[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function get() {
        return _notifications[key];
      }
    });
  });
});
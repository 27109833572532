define("zerorisk-frontend/utils/constants/saq-statuses", ["exports", "@babel/runtime/helpers/esm/defineProperty"], function (_exports, _defineProperty2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SAQStatusTypes = void 0;
  var _SAQStatusTypes$DRAFT;
  var SAQStatusTypes = {
    DRAFT: 'DRAFT',
    SUBMITTED: 'SUBMITTED',
    AWAITING_UPLOAD: 'AWAITING_UPLOAD',
    PENDING_APPROVAL: 'PENDING_APPROVAL',
    EXPIRING: 'EXPIRING',
    EXPIRED: 'EXPIRED',
    ARCHIVED: 'ARCHIVED'
  };
  _exports.SAQStatusTypes = SAQStatusTypes;
  var _default = (_SAQStatusTypes$DRAFT = {}, (0, _defineProperty2.default)(_SAQStatusTypes$DRAFT, SAQStatusTypes.DRAFT, {
    friendly: 'Draft',
    labelClass: 'label-primary'
  }), (0, _defineProperty2.default)(_SAQStatusTypes$DRAFT, SAQStatusTypes.SUBMITTED, {
    friendly: 'Submitted',
    labelClass: 'label-info'
  }), (0, _defineProperty2.default)(_SAQStatusTypes$DRAFT, SAQStatusTypes.AWAITING_UPLOAD, {
    friendly: 'Awaiting Upload',
    labelClass: 'label-info'
  }), (0, _defineProperty2.default)(_SAQStatusTypes$DRAFT, SAQStatusTypes.PENDING_APPROVAL, {
    friendly: 'Pending Approval',
    labelClass: 'label-warning'
  }), (0, _defineProperty2.default)(_SAQStatusTypes$DRAFT, SAQStatusTypes.EXPIRING, {
    friendly: 'Expiring',
    labelClass: 'label-warning'
  }), (0, _defineProperty2.default)(_SAQStatusTypes$DRAFT, SAQStatusTypes.EXPIRED, {
    friendly: 'Expired',
    labelClass: 'label-danger'
  }), (0, _defineProperty2.default)(_SAQStatusTypes$DRAFT, SAQStatusTypes.ARCHIVED, {
    friendly: 'Archived',
    labelClass: 'label-primary'
  }), _SAQStatusTypes$DRAFT);
  _exports.default = _default;
});
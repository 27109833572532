define("zerorisk-frontend/cldrs/ee", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*jslint eqeq: true*/
  var _default = [{
    "locale": "ee",
    "pluralRuleFunction": function pluralRuleFunction(n, ord) {
      if (ord) return "other";
      return n == 1 ? "one" : "other";
    },
    "fields": {
      "year": {
        "displayName": "ƒe",
        "relative": {
          "0": "ƒe sia",
          "1": "ƒe si gbɔ na",
          "-1": "ƒe si va yi"
        },
        "relativeTime": {
          "future": {
            "one": "le ƒe {0} me",
            "other": "le ƒe {0} me"
          },
          "past": {
            "one": "ƒe {0} si va yi",
            "other": "ƒe {0} si wo va yi"
          }
        }
      },
      "year-short": {
        "displayName": "ƒe",
        "relative": {
          "0": "ƒe sia",
          "1": "ƒe si gbɔ na",
          "-1": "ƒe si va yi"
        },
        "relativeTime": {
          "future": {
            "one": "le ƒe {0} me",
            "other": "le ƒe {0} me"
          },
          "past": {
            "one": "le ƒe {0} si va yi me",
            "other": "le ƒe {0} si va yi me"
          }
        }
      },
      "month": {
        "displayName": "ɣleti",
        "relative": {
          "0": "ɣleti sia",
          "1": "ɣleti si gbɔ na",
          "-1": "ɣleti si va yi"
        },
        "relativeTime": {
          "future": {
            "one": "le ɣleti {0} me",
            "other": "le ɣleti {0} wo me"
          },
          "past": {
            "one": "ɣleti {0} si va yi",
            "other": "ɣleti {0} si wo va yi"
          }
        }
      },
      "month-short": {
        "displayName": "ɣleti",
        "relative": {
          "0": "ɣleti sia",
          "1": "ɣleti si gbɔ na",
          "-1": "ɣleti si va yi"
        },
        "relativeTime": {
          "future": {
            "one": "le ɣleti {0} me",
            "other": "le ɣleti {0} wo me"
          },
          "past": {
            "one": "ɣleti {0} si va yi",
            "other": "ɣleti {0} si wo va yi"
          }
        }
      },
      "day": {
        "displayName": "ŋkeke",
        "relative": {
          "0": "egbe",
          "1": "etsɔ si gbɔna",
          "2": "nyitsɔ si gbɔna",
          "-2": "nyitsɔ si va yi",
          "-1": "etsɔ si va yi"
        },
        "relativeTime": {
          "future": {
            "one": "le ŋkeke {0} me",
            "other": "le ŋkeke {0} wo me"
          },
          "past": {
            "one": "ŋkeke {0} si va yi",
            "other": "ŋkeke {0} si wo va yi"
          }
        }
      },
      "day-short": {
        "displayName": "ŋkeke",
        "relative": {
          "0": "egbe",
          "1": "etsɔ si gbɔna",
          "2": "nyitsɔ si gbɔna",
          "-2": "nyitsɔ si va yi",
          "-1": "etsɔ si va yi"
        },
        "relativeTime": {
          "future": {
            "one": "le ŋkeke {0} me",
            "other": "le ŋkeke {0} wo me"
          },
          "past": {
            "one": "ŋkeke {0} si va yi",
            "other": "ŋkeke {0} si wo va yi"
          }
        }
      },
      "hour": {
        "displayName": "gaƒoƒo",
        "relative": {
          "0": "this hour"
        },
        "relativeTime": {
          "future": {
            "one": "le gaƒoƒo {0} me",
            "other": "le gaƒoƒo {0} wo me"
          },
          "past": {
            "one": "gaƒoƒo {0} si va yi",
            "other": "gaƒoƒo {0} si wo va yi"
          }
        }
      },
      "hour-short": {
        "displayName": "gaƒoƒo",
        "relative": {
          "0": "this hour"
        },
        "relativeTime": {
          "future": {
            "one": "le gaƒoƒo {0} me",
            "other": "le gaƒoƒo {0} wo me"
          },
          "past": {
            "one": "gaƒoƒo {0} si va yi",
            "other": "gaƒoƒo {0} si wo va yi"
          }
        }
      },
      "minute": {
        "displayName": "aɖabaƒoƒo",
        "relative": {
          "0": "this minute"
        },
        "relativeTime": {
          "future": {
            "one": "le aɖabaƒoƒo {0} me",
            "other": "le aɖabaƒoƒo {0} wo me"
          },
          "past": {
            "one": "aɖabaƒoƒo {0} si va yi",
            "other": "aɖabaƒoƒo {0} si wo va yi"
          }
        }
      },
      "minute-short": {
        "displayName": "aɖabaƒoƒo",
        "relative": {
          "0": "this minute"
        },
        "relativeTime": {
          "future": {
            "one": "le aɖabaƒoƒo {0} me",
            "other": "le aɖabaƒoƒo {0} wo me"
          },
          "past": {
            "one": "aɖabaƒoƒo {0} si va yi",
            "other": "aɖabaƒoƒo {0} si wo va yi"
          }
        }
      },
      "second": {
        "displayName": "sekend",
        "relative": {
          "0": "fifi"
        },
        "relativeTime": {
          "future": {
            "one": "le sekend {0} me",
            "other": "le sekend {0} wo me"
          },
          "past": {
            "one": "sekend {0} si va yi",
            "other": "sekend {0} si wo va yi"
          }
        }
      },
      "second-short": {
        "displayName": "sekend",
        "relative": {
          "0": "fifi"
        },
        "relativeTime": {
          "future": {
            "one": "le sekend {0} me",
            "other": "le sekend {0} wo me"
          },
          "past": {
            "one": "sekend {0} si va yi",
            "other": "sekend {0} si wo va yi"
          }
        }
      }
    }
  }];
  _exports.default = _default;
});
define("zerorisk-frontend/utils/constants/supported-locales", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/extends"], function (_exports, _defineProperty2, _extends3) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.localesShortMap = _exports.default = void 0;
  var LOCALES = ['bg', 'cz', 'dk', 'de', 'dut', 'ee', 'en', 'es', 'fr', 'gr', 'hr', 'it', 'lv', 'lt', 'hu', 'nl', 'no', 'pl', 'pt', 'ro', 'ru', 'rs', 'sk', 'si', 'fi', 'sv', 'tr', 'uk'];
  var localesShortMap = (0, _extends3.default)({
    'en-GB': 'en',
    'en-US': 'en',
    'en-EN': 'en',
    'fr-FR': 'fr',
    'fr-BE': 'fr',
    'fr-CA': 'fr',
    'nl-NL': 'nl',
    'nl-BE': 'nl',
    'es-ES': 'es',
    'pt-PT': 'pt',
    'it-IT': 'it'
  }, LOCALES.reduce(function (acc, locale) {
    return (0, _extends3.default)({}, acc, (0, _defineProperty2.default)({}, locale, locale));
  }, {}));
  _exports.localesShortMap = localesShortMap;
  var _default = LOCALES;
  _exports.default = _default;
});
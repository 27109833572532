define("zerorisk-frontend/templates/components/modals/raq-assign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "+M/dP8A4",
    "block": "{\"symbols\":[\"@searchTask\",\"@selectedAssignee\",\"@assigneeType\",\"@initialSegmentOptions\",\"@initialMerchantOptions\",\"@initialOptions\"],\"statements\":[[4,\"ember-remodal\",null,[[\"forService\",\"name\"],[true,\"raq-assign\"]],{\"statements\":[[0,\"  \"],[7,\"header\"],[11,\"class\",\"modal-header\"],[9],[0,\"\\n    \"],[7,\"h1\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-file-text m-r-5\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\" \"],[1,[27,\"t\",[\"app.button_assign_raq\"],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"modal-body rounded-b-corners\"],[9],[0,\"\\n    \"],[1,[27,\"forms/raq/assign-new\",null,[[\"initialOptions\",\"initialMerchantOptions\",\"initialSegmentOptions\",\"assigneeType\",\"selectedAssignee\",\"searchTask\",\"onSubmit\",\"onCancel\"],[[22,6,[]],[22,5,[]],[22,4,[]],[22,3,[]],[22,2,[]],[22,1,[]],[27,\"action\",[[22,0,[]],\"onSubmit\"],null],[27,\"action\",[[22,0,[]],\"onCancel\"],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/modals/raq-assign.hbs"
    }
  });
  _exports.default = _default;
});
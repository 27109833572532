define("zerorisk-frontend/templates/components/location-entity-selector/info-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "VbgKjCuK",
    "block": "{\"symbols\":[\"@symbol\",\"@text\"],\"statements\":[[7,\"div\"],[11,\"class\",\"m-t-10 m-b-10\"],[12,\"style\",[28,[\"width: 100%; float: none; \",[27,\"if\",[[22,0,[\"borderDashed\"]],\"border: 1px dashed #e2e2e2;\"],null],\" \",[27,\"if\",[[22,0,[\"borderSolid\"]],\"border: 1px dashed #e2e2e2;\"],null]]]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row no-margin\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-xs-1\"],[11,\"style\",\"padding: 15px 20px;\"],[9],[0,\"\\n      \"],[7,\"i\"],[12,\"class\",[28,[\"fa fa-\",[22,1,[]]]]],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-xs-9\"],[11,\"style\",\"padding: 15px;\"],[9],[0,\"\\n        \"],[1,[22,2,[]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-xs-1\"],[9],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"onClick\"]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/location-entity-selector/info-block.hbs"
    }
  });
  _exports.default = _default;
});
define("zerorisk-frontend/templates/invitation/sp/confirmation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "jSz65oJ8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n\\t\"],[7,\"div\"],[11,\"class\",\"container p-t-30\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\"],[11,\"class\",\"card card-default bg-success-dark box-shadow-spread padding-20\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"h3\"],[11,\"class\",\"text-white bold\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-check m-r-5 animated rubberBand\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\" \"],[1,[27,\"t\",[\"online_registration.youre_in\"],null],false],[10],[0,\"\\n\\t\\t\\t\"],[7,\"h5\"],[11,\"class\",\"text-white bold\"],[9],[1,[27,\"t\",[\"online_registration.youre_all_set\"],null],false],[10],[0,\"\\n\\t\\t\\t\"],[7,\"h6\"],[11,\"class\",\"text-white bold\"],[9],[1,[27,\"t\",[\"online_registration.you_should_recieve_email\"],null],false],[0,\" \"],[1,[22,0,[\"model\",\"user\",\"email\"]],false],[0,\", \"],[1,[27,\"t\",[\"online_registration.you_can_login\"],null],false],[0,\" .\"],[10],[0,\"\\n\\t\\t\\t\"],[7,\"div\"],[11,\"class\",\"row padding-10 p-r-20 d-flex ml-auto\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"a\"],[12,\"href\",[27,\"href-to\",[\"login\"],null]],[11,\"class\",\"m-l-5 btn btn-lg btn-success btn-rounded bold\"],[9],[1,[27,\"t\",[\"online_registration.go_to_login\"],null],false],[10],[0,\"\\n\\t\\t\\t\"],[10],[0,\"\\n\\t\\t\"],[10],[0,\"\\n\\t\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/invitation/sp/confirmation.hbs"
    }
  });
  _exports.default = _default;
});
define("zerorisk-frontend/templates/components/text-wizard/upload-files-answer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "8FQkfbx+",
    "block": "{\"symbols\":[\"@answers\",\"@answer\"],\"statements\":[[7,\"div\"],[11,\"class\",\"upload-files-answer\"],[9],[0,\"\\n  \"],[1,[27,\"file-tools/enrollment-file-uploader\",null,[[\"onNextClick\"],[[27,\"action\",[[22,0,[]],[22,2,[]],[22,1,[\"yesBringsTo\"]]],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "zerorisk-frontend/templates/components/text-wizard/upload-files-answer.hbs"
    }
  });
  _exports.default = _default;
});